import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Container, Card, ButtonGroup, Button, Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { triggerBase64Download } from 'common-base64-downloader-react';
import { 
  getAnnualLeave,
  getSickLeave,
  getMedicalBenefits,
  getSpecialLeave,
  getOtherLeave,
  getUnpaidLeave,
  getMaternityLeave,
  getHospitalizationLeave,
  postLeaveMovementReportDownload,
  postLeaveMovementReportDownloadPDF,
  getAEmployee
} from "../../3.Store/LeaveMovementSlice";
import LeaveTable from './HRLeaveReportTable';
import { ThreeDots } from 'react-loader-spinner';

const HRLeaveReportForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  // Fetch leave data from Redux store
  const annualLeaveData = useSelector(state => state.LeaveMovementSlice.annualLeave); 
  const sickLeaveData = useSelector(state => state.LeaveMovementSlice.sickLeave);
  const medicalBenefitsData = useSelector(state => state.LeaveMovementSlice.medicalBenefits);
  const specialLeaveData = useSelector(state => state.LeaveMovementSlice.specialLeave);
  const otherLeaveData = useSelector(state => state.LeaveMovementSlice.otherLeave);
  const unpaidLeaveData = useSelector(state => state.LeaveMovementSlice.unpaidLeave);
  const maternityLeaveData = useSelector(state => state.LeaveMovementSlice.maternityLeave);
  const hospitalizationLeaveData = useSelector(state => state.LeaveMovementSlice.hospitalizationLeave);
  const employee = useSelector(state => state.LeaveMovementSlice.employee);

  const leaveSections = [
    { title: "Annual Leave", data: annualLeaveData },
    { title: "Sick Leave", data: sickLeaveData },
    { title: "Medical Benefits", data: medicalBenefitsData },
    { title: "Special Leave", data: specialLeaveData },
    { title: "Other Leave", data: otherLeaveData },
    { title: "Unpaid Leave", data: unpaidLeaveData },
    { title: "Maternity Leave", data: maternityLeaveData },
    { title: "Hospitalization Leave", data: hospitalizationLeaveData },
  ];

  // Fetch leave data when component mounts
  useEffect(() => {
    const fetchData = async () => {
        try {
          setLoading(true);
          await dispatch(getAnnualLeave({ id, year: selectedYear }));
          await dispatch(getSickLeave({ id, year: selectedYear }));
          await dispatch(getMedicalBenefits({ id, year: selectedYear }));
          await dispatch(getSpecialLeave({ id, year: selectedYear }));
          await dispatch(getOtherLeave({ id, year: selectedYear }));
          await dispatch(getUnpaidLeave({ id, year: selectedYear }));
          await dispatch(getMaternityLeave({ id, year: selectedYear }));
          await dispatch(getHospitalizationLeave({ id, year: selectedYear }));

          if(id){
            dispatch(getAEmployee({id}));
          }
        } catch (error) {
          console.error('Error fetching leave data:', error);
        }
        finally {
          setLoading(false); // Set loading to false after data fetching completes
        }
    };
    fetchData();
  }, [id, selectedYear]);

  const handleClickView = () => {};

  const handleBtnDownload = () => {
    setLoading(true);
    const report = dispatch(postLeaveMovementReportDownload({ year: selectedYear, employee }));
    report.then((data) => {
        try {
            // Ensure the payload is a string
            if (typeof data.payload !== 'string') {
                throw new Error('Invalid payload format');
            }

            // Decode the base64-encoded data
            const base64EXCEL = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data.payload}`;
            
            // Trigger download
            triggerBase64Download(base64EXCEL, "Leave_Movement_Records_" + selectedYear + " - " + employee.fullname);
        } catch (error) {
            console.error('Error handling download:', error);
        }
        finally {
          setLoading(false); // Set loading to false after data fetching completes
        }
    }).catch((error) => {
        console.error('Error fetching report data:', error);
    });
  };

  const handleBtnDownloadPDF = () => {
    setLoading(true);
    const report = dispatch(postLeaveMovementReportDownloadPDF({ year: selectedYear, employee }));
    report.then((data) => {
        try {
            // Ensure the payload is a string
            if (typeof data.payload !== 'string') {
                throw new Error('Invalid payload format');
            }

            // Decode the base64-encoded data
            const base64EXCEL = `data:application/pdf;base64,${data.payload}`;
            
            // Trigger download
            triggerBase64Download(base64EXCEL, "Leave_Movement_Records_" + selectedYear + " - " + employee.fullname);
        } catch (error) {
            console.error('Error handling download:', error);
        }
        finally {
          setLoading(false); // Set loading to false after data fetching completes
        }
    }).catch((error) => {
        console.error('Error fetching report data:', error);
    });
  };  

  const handleBtnBack = () => {
    navigate(-1);
  };

  // Function to generate options for the dropdown menu
  const renderYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    // for (let year = currentYear; year >= currentYear - 5; year--) {
    //     years.push(
    //         <option key={year} value={year}>{year}</option>
    //     );
    // }
    // return years;
    // Add 2025 first
    years.push(
      <option key={currentYear + 1} value={currentYear + 1}>{currentYear + 1}</option>
    );

    // Loop for current year and previous 5 years
    for (let year = currentYear; year >= currentYear - 2; year--) {
        years.push(
            <option key={year} value={year}>{year}</option>
        );
    }
    
    return years;
  };

  return (
    <Container>
      {loading && (
        <ThreeDots
          visible={loading}
          height="80"
          width="80"
          color="#4fa94d"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: '9999',
          }}
        />
      )}
      <Card className="CardContent">

        <div className="d-flex justify-content-between">
          <ButtonGroup className="my-2">
            <Button className="Button" onClick={handleBtnBack}>
              Back
            </Button>
          </ButtonGroup>
        </div>

        <h1 className="TitleHeader">Leave Movement Records</h1>

        <Col md={1} sm={1} style={{ marginLeft: "auto" }}>
          {/* Dropdown menu for selecting the year */}
          <Form.Select value={selectedYear} onChange={(e) => setSelectedYear(parseInt(e.target.value))}>
              {renderYearOptions()}
          </Form.Select>
        </Col>

        <div className="d-flex align-items-center">
          <h1 className="MiniTitleHeader">{employee?.fullname}</h1>
          
          <ButtonGroup className="my-2 ms-auto">
            <Button className="Button" onClick={handleBtnDownloadPDF}>
              Download PDF 
            </Button>
            &nbsp;&nbsp;
            <Button className="Button" onClick={handleBtnDownload}>
              Download Excel
            </Button>
          </ButtonGroup>
        </div>

        {leaveSections.map((section, index) => (
          <div key={index}>
            <h1 className="MiniTitleHeader mt-3">{section.title}</h1>
            {/* <LeaveTable title={section.title} leaveData={section.data} /> */}
            <LeaveTable title={section.title} leaveData={section.data} year={selectedYear} loading={loading} />
          </div>
        ))}

        <div className="d-flex justify-content-between">
          <ButtonGroup className="my-2">
            <Button className="Button" onClick={handleBtnBack}>
              Back
            </Button>
          </ButtonGroup>
          &nbsp; &nbsp;
          <ButtonGroup className="my-2">
            <Button className="Button" onClick={handleBtnDownloadPDF}>
              Download PDF 
            </Button>       
            &nbsp;&nbsp;
            <Button className="Button" onClick={handleBtnDownload}>
              Download Excel
            </Button>
          </ButtonGroup>
        </div>
      </Card>
    </Container>
  );
};
export default HRLeaveReportForm;
