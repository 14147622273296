import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiServices } from "../4.Utils/apiServices";
import moment from "moment/moment";

const namespace = "leave/accrual/year";

export const getLeaveSummaryEmployeeLastYear = createAsyncThunk(
  `${namespace}/employee/year`,
  async ({ id, year }, { rejectWithValue }) => {
    const api = "list_leave_summary_employee_year";
    const opt = {
      replace: [
        { search: "{employee_id}", replace: id },
        { search: "{year}", replace: year },
      ],
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getLeaveAwardedThisYear = createAsyncThunk(
  `${namespace}/awarded/employee/year`,
  async ({ id, year }, { rejectWithValue }) => {
    const api = "awardedthisyear";
    const opt = {
      replace: [
        { search: "{employee_id}", replace: id },
        { search: "{year}", replace: year },
      ],
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getListsBeforeForfeit = createAsyncThunk(
  `${namespace}/before/forfeit/employee/year`,
  async ({ id, year }, { rejectWithValue }) => {
    const api = "before_forfeit";
    const opt = {
      replace: [
        { search: "{employee_id}", replace: id },
        { search: "{year}", replace: year },
      ],
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
  loading: false,
  error: false,
  currentPage: 1,
  currentPageCount: 0,
  summaries: [],
  awarded: {},
  bfForfeit: [],
}

const LeaveAccrualYearEndSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
      builder
      //get list by employee id and year
      .addCase(getLeaveSummaryEmployeeLastYear.pending, (state) => {
          state.loading = true;
          state.error = false;
      })
      .addCase(getLeaveSummaryEmployeeLastYear.fulfilled, (state, action) => {
          state.loading = false;
          state.summaries = action.payload;
          console.log(action.payload);
      })
      .addCase(getLeaveSummaryEmployeeLastYear.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
      })

      .addCase(getLeaveAwardedThisYear.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getLeaveAwardedThisYear.fulfilled, (state, action) => {
          state.loading = false;
          state.awarded = action.payload;
          console.log(action.payload);
      })
      .addCase(getLeaveAwardedThisYear.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
      })

      .addCase(getListsBeforeForfeit.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getListsBeforeForfeit.fulfilled, (state, action) => {
          state.loading = false;
          state.bfForfeit = action.payload;
          console.log(action.payload);
      })
      .addCase(getListsBeforeForfeit.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
      })
  }
});

export const { } = LeaveAccrualYearEndSlice.actions;

export default LeaveAccrualYearEndSlice.reducer;