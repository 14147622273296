import { useNavigate, useParams } from "react-router-dom";
import {
  Container,
  Card,
  Row,
  Col,
  Form,
  ButtonGroup,
  Button,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import moment from "moment/moment";
import {
  getLeaveAccrual,
  updateEncashmentDetail,
  updateEncashment,
  submitEncashment,
  resetList,
  updateList,
  updateForfeitDetail,
} from "../../3.Store/LeaveAccrualSlice";
import { ThreeDots } from "react-loader-spinner";
import Swal from "sweetalert2";

const LeaveAccrualForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accrualDetails = useSelector(
    (state) => state.LeaveAccrualSlice.accrualDetails
  );
  const encashment = useSelector((state) => state.LeaveAccrualSlice.encashment);
  const { day_encashment, encashment_date } = encashment;
  const [loading, setLoading] = useState(false);
  const statusDescription =
    accrualDetails?.encashment?.status?.description || "";

  useEffect(() => {
    setLoading(true);
    if (id) {
      dispatch(getLeaveAccrual({ id }))
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [id]);

  useEffect(() => {
    if (statusDescription !== "") {
      const entity = {
        ...encashment,
        day_encashment: accrualDetails?.encashment?.day_encashment,
        encashment_date: moment(
          accrualDetails?.encashment?.encashment_date
        ).format("YYYY-MM-DD"),
      };
      dispatch(updateEncashment(entity));
    } else {
      dispatch(resetList());
    }
  }, [statusDescription]);

  const handleEncashmentChange = (e) => {
    const encashment_day = { ...encashment, day_encashment: e.target.value };
    console.log(encashment_day);
    dispatch(updateEncashment(encashment_day));
  };

  const handleEncashmentDateChange = (e) => {
    const encashment_date = { ...encashment, encashment_date: e.target.value };
    console.log(encashment_date);
    dispatch(updateEncashment(encashment_date));
  };

  const handleBtnSubmit = () => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        dispatch(submitEncashment({ id, ...encashment }))
          .unwrap()
          .then((response) => {
            setLoading(false);
            dispatch(getLeaveAccrual({ id }));
          })
          .catch((err) => {
            setLoading(false);
            Swal.fire("Error", err.response.data, "error");
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Cancel Create Encashment", "error");
      }
    });
  };

  const handleBtnUpdateEncash = () => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        const encashment_id = accrualDetails?.encashment?.id;
        console.log(encashment_id);
        setLoading(true);
        dispatch(updateEncashmentDetail({ id: encashment_id, ...encashment }))
          .unwrap()
          .then(() => {
            setLoading(false);
            dispatch(getLeaveAccrual({ id }));
          })
          .catch((err) => {
            setLoading(false);
            Swal.fire("Error", err.response.data, "error");
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Cancel Update Encashment", "error");
      }
    });
  };

  const handleForfeitChange = (e) => {
    const entity = {
      ...accrualDetails,
      forfeit: {
        accrual_date: moment(accrualDetails?.forfeit?.accrual_date).format(
          "YYYY-MM-DD"
        ),
        forfeit: e.target.value,
        status: { id: 1 },
        id: accrualDetails?.forfeit?.id,
      },
    };
    dispatch(updateList(entity));
  };

  const handleForfeitDateChange = (e) => {
    const entity = {
      ...accrualDetails,
      forfeit: {
        accrual_date: moment(e.target.value).format("YYYY-MM-DD"),
        forfeit: accrualDetails?.forfeit?.forfeit,
        status: { id: 1 },
        id: accrualDetails?.forfeit?.id,
      },
    };
    dispatch(updateList(entity));
  };

  const handleBtnForfeitNow = () => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        const forfeit_id = accrualDetails?.forfeit?.id;
        const entity = accrualDetails?.forfeit;
        setLoading(true);
        dispatch(updateForfeitDetail({ id: forfeit_id, ...entity }))
          .unwrap()
          .then(() => {
            setLoading(false);
            dispatch(getLeaveAccrual({ id }));
          })
          .catch((err) => {
            setLoading(false);
            Swal.fire("Error", err.response.data, "error");
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Cancel Update Forfeit", "error");
      }
    });
  };

  const handleBtnBack = () => {
    dispatch(resetList());
    navigate(-1);
  };

  return (
    <Container>
      {loading && (
        <ThreeDots
          visible={loading}
          height="80"
          width="80"
          color="#4fa94d"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "9999",
          }}
        />
      )}
      <Card className="CardContent">
        <h1 className="TitleHeader">Leave Accrual 2024</h1>
        <hr className="hr-white" />
        <h1 className="MiniTitleHeader">Employee</h1>

        <Row className="my-2">
          <Col md={6} sm={6}>
            <Row>
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel fs-5">FullName</Form.Label>
              </Col>
              <Col md={8} sm={8}>
                <Form.Control
                  value={accrualDetails?.employee.fullname}
                  readOnly
                />
              </Col>
            </Row>
          </Col>
          <Col md={6} sm={6}>
            <Row>
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel fs-5">Company</Form.Label>
              </Col>
              <Col md={8} sm={8}>
                <Form.Control
                  value={accrualDetails?.employee.company.description}
                  readOnly
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="my-2">
          <Col md={6} sm={6}>
            <Row>
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel fs-5">Staff ID</Form.Label>
              </Col>
              <Col md={8} sm={8}>
                <Form.Control value={accrualDetails?.employee.sid} readOnly />
              </Col>
            </Row>
          </Col>
          <Col md={6} sm={6}>
            <Row>
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel fs-5">Employee Category</Form.Label>
              </Col>
              <Col md={8} sm={8}>
                <Form.Control
                  value={accrualDetails?.employee.employee_category.description}
                  readOnly
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="my-2">
          <Col md={6} sm={6}>
            <Row>
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel fs-5">Date Joined</Form.Label>
              </Col>
              <Col md={8} sm={8}>
                <Form.Control
                  value={moment(accrualDetails?.employee.date_joined).format(
                    "YYYY-MM-DD"
                  )}
                  readOnly
                />
              </Col>
            </Row>
          </Col>
          <Col md={6} sm={6}>
            <Row>
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel fs-5">Supervisor</Form.Label>
              </Col>
              <Col md={8} sm={8}>
                <Form.Control
                  value={accrualDetails?.employee.supervisor.fullname}
                  readOnly
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="my-2">
          <Col md={4} sm={4}>
            <Form.Label className="FormLabel fs-5">To be cleared</Form.Label>
          </Col>
          <Col md={8} sm={8}>
            <Form.Control value={accrualDetails?.to_be_cleared} readOnly />
          </Col>
        </Row>

        <hr className="hr-white" />

        <h1 className="MiniTitleHeader">Encashment</h1>

        <Row className="my-2">
          <Col md={4} sm={4}>
            <Form.Label className="FormLabel fs-5">Encashment Date</Form.Label>
          </Col>
          <Col md={8} sm={8}>
            <Form.Control
              type="date"
              name="encashment_date"
              value={moment(encashment_date).format("YYYY-MM-DD")}
              onChange={handleEncashmentDateChange}
            />
          </Col>
        </Row>

        <Row className="my-2">
          <Col md={4} sm={4}>
            <Form.Label className="FormLabel fs-5">No of Day(s)</Form.Label>
          </Col>
          <Col md={8} sm={8}>
            <Form.Control
              name="day_encashment"
              value={day_encashment}
              onChange={handleEncashmentChange}
            />
          </Col>
        </Row>

        <Row className="my-2">
          <Col md={4} sm={4}>
            <Form.Label className="FormLabel fs-5">Status</Form.Label>
          </Col>
          <Col md={8} sm={8}>
            <Form.Control
              value={accrualDetails?.encashment?.status?.description || ""}
              readOnly
            />
          </Col>
        </Row>

        <ButtonGroup className="my-2" style={{ marginLeft: "auto" }}>
          {statusDescription === "" && (
            <Button className="Button" onClick={handleBtnSubmit}>
              Submit
            </Button>
          )}
          {statusDescription === "PENDING" && (
            <Button className="Button" onClick={handleBtnUpdateEncash}>
              Encash Now
            </Button>
          )}
          {statusDescription === "OK" && (
            <Button className="Button" onClick={handleBtnUpdateEncash}>
              Update Encash
            </Button>
          )}
        </ButtonGroup>

        <hr className="hr-white" />

        <h1 className="MiniTitleHeader">Forfeit</h1>
        <Row className="my-2">
          <Col md={4} sm={4}>
            <Form.Label className="FormLabel fs-5">Forfeit Date</Form.Label>
          </Col>
          <Col md={8} sm={8}>
            <Form.Control
              type="date"
              value={moment(accrualDetails?.forfeit?.accrual_date).format(
                "YYYY-MM-DD"
              )}
              onChange={handleForfeitDateChange}
            />
          </Col>
        </Row>

        <Row className="my-2">
          <Col md={4} sm={4}>
            <Form.Label className="FormLabel fs-5">No of Day(s)</Form.Label>
          </Col>
          <Col md={8} sm={8}>
            <Form.Control
              value={accrualDetails?.forfeit?.forfeit}
              onChange={handleForfeitChange}
            />
          </Col>
        </Row>

        <Row className="my-2">
          <Col md={4} sm={4}>
            <Form.Label className="FormLabel fs-5">Status</Form.Label>
          </Col>
          <Col md={8} sm={8}>
            <Form.Control
              value={accrualDetails?.forfeit?.status?.description}
              readOnly
            />
          </Col>
        </Row>

        <ButtonGroup className="my-2" style={{ marginLeft: "auto" }}>
          {accrualDetails?.forfeit?.status?.description === "OK" ? (
            <Button className="Button" onClick={handleBtnForfeitNow}>
              Update Forfeit
            </Button>
          ) : (
            <Button className="Button" onClick={handleBtnForfeitNow}>
              Forfeit Now
            </Button>
          )}
        </ButtonGroup>

        <hr className="hr-white" />

        <div className="d-flex justify-content-between">
          <ButtonGroup className="my-2">
            <Button className="Button" onClick={handleBtnBack}>
              Back
            </Button>
          </ButtonGroup>
        </div>
      </Card>
    </Container>
  );
};
export default LeaveAccrualForm;
