import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiServices } from "../4.Utils/apiServices";

const namespace = "leave/movement/records/lists";

export const getAnnualLeave = createAsyncThunk(
  `${namespace}/lists`,
  async (body, { rejectWithValue }) => {
    const api = "get_annualLeave";
    const replace = {
      search: "{id}",
      replace: body.id,
    };
    const replace2 = {
      search: "{year}",
      replace: body.year,
    };
    const opt = {
      replace: [replace, replace2],
      body: body,
    };

    try {
      const response = await apiServices(api, opt);

      // Handle cases where the response data might not be an array
      const responseData = Array.isArray(response.data) ? response.data : [];

      let openingBalanceIncluded = false;        

      const filteredRecords = responseData.filter(record => {
        const description = record.leave_move_record.leave_movement_type.description;
        const cfValue = record.leave_move_record.cf;

        if (description === "CLOSING BALANCE") {
          return false; // Exclude "CLOSING BALANCE"
        }

        if (description === "OPENING BALANCE") {
          if (openingBalanceIncluded) {
            return false; // Skip additional "OPENING BALANCE" records after the first one
          } else {
            openingBalanceIncluded = true; // Include the first "OPENING BALANCE" record
            return true;
          }
        }

        // Include all other records
        return true;
      });

      return filteredRecords;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getSickLeave = createAsyncThunk(
    `${namespace}/sick/lists`,
    async (body, { rejectWithValue }) => {
      const api = "get_sickLeave";
      const replace = {
        search: "{id}",
        replace: body.id,
      };
      const replace2 = {
        search: "{year}",
        replace: body.year,
      };
      const opt = {
        replace: [ replace, replace2 ],
        body: body,
      };
  
      try {
        const response = await apiServices(api, opt);
      
        // Handle cases where the response data might not be an array
        const responseData = Array.isArray(response.data) ? response.data : [];
      
        // Track if an "OPENING BALANCE" record with cf == 0.0 has already been included
        let openingBalanceIncluded = false;
      
        // Filter the records based on specific criteria
        const filteredRecords = responseData.filter(record => {
          const description = record.leave_move_record.leave_movement_type.description;
          const cfValue = record.leave_move_record.cf;
      
          if (description === "CLOSING BALANCE") {
            return false; // Exclude "CLOSING BALANCE"
          }
      
          if (description === "OPENING BALANCE") {
            if (openingBalanceIncluded || cfValue !== "0.0") {
              return false; // Skip additional "OPENING BALANCE" records or those where cf != 0.0
            } else {
              openingBalanceIncluded = true; // Include the first "OPENING BALANCE" record where cf == 0.0
              return true;
            }
          }
      
          // Include other records
          return true;
        });
      
        return filteredRecords;
      } catch (err) {
        return rejectWithValue(err);
      }      
    }
);

export const getSpecialLeave = createAsyncThunk(
    `${namespace}/special/lists`,
    async (body, { rejectWithValue }) => {
      const api = "get_specialLeave";
      const replace = {
        search: "{id}",
        replace: body.id,
      };
      const replace2 = {
        search: "{year}",
        replace: body.year,
      };
      const opt = {
        replace: [ replace, replace2 ],
        body: body,
      };
  
      try {
        const response = await apiServices(api, opt);
      
        // Handle cases where the response data might not be an array
        const responseData = Array.isArray(response.data) ? response.data : [];
      
        // Track if an "OPENING BALANCE" record with cf == 0.0 has already been included
        let openingBalanceIncluded = false;
      
        // Filter the records based on specific criteria
        const filteredRecords = responseData.filter(record => {
          const description = record.leave_move_record.leave_movement_type.description;
          const cfValue = record.leave_move_record.cf;
      
          if (description === "CLOSING BALANCE") {
            return false; // Exclude "CLOSING BALANCE"
          }
      
          if (description === "OPENING BALANCE") {
            if (openingBalanceIncluded || cfValue !== "0.0") {
              return false; // Skip additional "OPENING BALANCE" records or those where cf != 0.0
            } else {
              openingBalanceIncluded = true; // Include the first "OPENING BALANCE" record where cf == 0.0
              return true;
            }
          }
      
          // Include other records
          return true;
        });
      
        return filteredRecords;
      } catch (err) {
        return rejectWithValue(err);
      }      
    }
);

export const getOtherLeave = createAsyncThunk(
    `${namespace}/other/lists`,
    async (body, { rejectWithValue }) => {
      const api = "get_otherLeave";
      const replace = {
        search: "{id}",
        replace: body.id,
      };
      const replace2 = {
        search: "{year}",
        replace: body.year,
      };
      const opt = {
        replace: [ replace, replace2 ],
        body: body,
      };
  
      try {
        const response = await apiServices(api, opt);
        
        // Handle cases where the response data might not be an array
        const responseData = Array.isArray(response.data) ? response.data : [];
        
        // Filter the records based on specific criteria
        const filteredRecords = responseData.filter(record =>
          record.leave_move_record.leave_movement_type.description !== "OPENING BALANCE" && 
          record.leave_move_record.leave_movement_type.description !== "CLOSING BALANCE"
        );
        
        return filteredRecords;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
);

export const getUnpaidLeave = createAsyncThunk(
    `${namespace}/unpaid/lists`,
    async (body, { rejectWithValue }) => {
      const api = "get_unpaidLeave";
      const replace = {
        search: "{id}",
        replace: body.id,
      };
      const replace2 = {
        search: "{year}",
        replace: body.year,
      };
      const opt = {
        replace: [ replace, replace2 ],
        body: body,
      };
  
      try {
        const response = await apiServices(api, opt);
      
        // Handle cases where the response data might not be an array
        const responseData = Array.isArray(response.data) ? response.data : [];
      
        // Track if an "OPENING BALANCE" record with cf == 0.0 has already been included
        let openingBalanceIncluded = false;
      
        // Filter the records based on specific criteria
        const filteredRecords = responseData.filter(record => {
          const description = record.leave_move_record.leave_movement_type.description;
          const cfValue = record.leave_move_record.cf;
      
          if (description === "CLOSING BALANCE") {
            return false; // Exclude "CLOSING BALANCE"
          }
      
          if (description === "OPENING BALANCE") {
            if (openingBalanceIncluded || cfValue !== "0.0") {
              return false; // Skip additional "OPENING BALANCE" records or those where cf != 0.0
            } else {
              openingBalanceIncluded = true; // Include the first "OPENING BALANCE" record where cf == 0.0
              return true;
            }
          }
      
          // Include other records
          return true;
        });
      
        return filteredRecords;
      } catch (err) {
        return rejectWithValue(err);
      }      
    }
);

export const getMedicalBenefits = createAsyncThunk(
    `${namespace}/medical/benifits/lists`,
    async (body, { rejectWithValue }) => {
      const api = "get_medicalbenefits";
      const replace = {
        search: "{id}",
        replace: body.id,
      };
      const replace2 = {
        search: "{year}",
        replace: body.year,
      };
      const opt = {
        replace: [ replace, replace2 ],
        body: body,
      };
  
      try {
        const response = await apiServices(api, opt);
      
        // Handle cases where the response data might not be an array
        const responseData = Array.isArray(response.data) ? response.data : [];
      
        // Track if an "OPENING BALANCE" record with cf == 0.0 has already been included
        let openingBalanceIncluded = false;
      
        // Filter the records based on specific criteria
        const filteredRecords = responseData.filter(record => {
          const description = record.leave_move_record.leave_movement_type.description;
          const cfValue = record.leave_move_record.cf;
      
          if (description === "CLOSING BALANCE") {
            return false; // Exclude "CLOSING BALANCE"
          }
      
          if (description === "OPENING BALANCE") {
            if (openingBalanceIncluded || cfValue !== "0.00") {
              return false; // Skip additional "OPENING BALANCE" records or those where cf != 0.0
            } else {
              openingBalanceIncluded = true; // Include the first "OPENING BALANCE" record where cf == 0.0
              return true;
            }
          }
      
          // Include other records
          return true;
        });
      
        return filteredRecords;
      } catch (err) {
        return rejectWithValue(err);
      }      
    }
);

export const getMaternityLeave = createAsyncThunk(
    `${namespace}/maternity/lists`,
    async (body, { rejectWithValue }) => {
      const api = "get_maternityLeave";
      const replace = {
        search: "{id}",
        replace: body.id,
      };
      const replace2 = {
        search: "{year}",
        replace: body.year,
      };
      const opt = {
        replace: [ replace, replace2 ],
        body: body,
      };
  
      try {
        const response = await apiServices(api, opt);
      
        // Handle cases where the response data might not be an array
        const responseData = Array.isArray(response.data) ? response.data : [];
      
        // Track if an "OPENING BALANCE" record with cf == 0.0 has already been included
        let openingBalanceIncluded = false;
      
        // Filter the records based on specific criteria
        const filteredRecords = responseData.filter(record => {
          const description = record.leave_move_record.leave_movement_type.description;
          const cfValue = record.leave_move_record.cf;
      
          if (description === "CLOSING BALANCE") {
            return false; // Exclude "CLOSING BALANCE"
          }
      
          if (description === "OPENING BALANCE") {
            if (openingBalanceIncluded || cfValue !== "0.0") {
              return false; // Skip additional "OPENING BALANCE" records or those where cf != 0.0
            } else {
              openingBalanceIncluded = true; // Include the first "OPENING BALANCE" record where cf == 0.0
              return true;
            }
          }
      
          // Include other records
          return true;
        });
      
        return filteredRecords;
      } catch (err) {
        return rejectWithValue(err);
      }      
    }
);

export const getHospitalizationLeave = createAsyncThunk(
    `${namespace}/hospitalization/lists`,
    async (body, { rejectWithValue }) => {
      const api = "get_hospitalization";
      const replace = {
        search: "{id}",
        replace: body.id,
      };
      const replace2 = {
        search: "{year}",
        replace: body.year,
      };
      const opt = {
        replace: [ replace, replace2 ],
        body: body,
      };
  
      try {
        const response = await apiServices(api, opt);
      
        // Handle cases where the response data might not be an array
        const responseData = Array.isArray(response.data) ? response.data : [];
      
        // Track if an "OPENING BALANCE" record with cf == 0.0 has already been included
        let openingBalanceIncluded = false;
      
        // Filter the records based on specific criteria
        const filteredRecords = responseData.filter(record => {
          const description = record.leave_move_record.leave_movement_type.description;
          const cfValue = record.leave_move_record.cf;
      
          if (description === "CLOSING BALANCE") {
            return false; // Exclude "CLOSING BALANCE"
          }
      
          if (description === "OPENING BALANCE") {
            if (openingBalanceIncluded || cfValue !== "0.0") {
              return false; // Skip additional "OPENING BALANCE" records or those where cf != 0.0
            } else {
              openingBalanceIncluded = true; // Include the first "OPENING BALANCE" record where cf == 0.0
              return true;
            }
          }
      
          // Include other records
          return true;
        });
      
        return filteredRecords;
      } catch (err) {
        return rejectWithValue(err);
      }      
    }
);

export const postLeaveMovementReportDownload = createAsyncThunk(
    `${namespace}/download`,
    async (body, { rejectWithValue }) => {
      const api = "download_leaveRecords_excel";
      const opt = {
        body: body,
      };
  
      try {
        const response = await apiServices(api, opt);
        return response.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
);

export const postLeaveMovementReportDownloadPDF = createAsyncThunk(
  `${namespace}/downloadPDF`,
  async (body, { rejectWithValue }) => {
    const api = "download_leaveRecords_pdf";
    const opt = {
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const postsearchText = createAsyncThunk(
    `${namespace}/search`,
    async (body, { rejectWithValue }) => {
        const api = "search_employee";
        const opt = {
            body: body,
        };
    
        try {
            const response = await apiServices(api, opt);
            return response.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

//Get Details
export const getLeaveApplicationDetails = createAsyncThunk(
  `${namespace}/details`,
  async (body, { rejectWithValue }) => {
    const api = "get_leave_application_details";
    const replace = {
      search: "{leave_application_id}",
      replace: body.id,
    };
    const opt = {
      replace: replace,
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//get a employee
export const getAEmployee = createAsyncThunk(
  `${namespace}/employee/details`,
  async (body, { rejectWithValue }) => {
    const api = "get_a_employee";
    const replace = {
      search: "{employee_id}",
      replace: body.id,
    };
    const opt = {
      replace: replace,
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
    search: { searchText: "", active: "1" },
    employees: [],
    specialLeave: [],
    otherLeave: [],
    unpaidLeave: [],
    medicalBenefits: [],
    maternityLeave: [],
    hospitalizationLeave: [],
    sickLeave: [],
    annualLeave: [],
    loading: false,
    error: false,
    details: {},
    employee: {}
};

const LeaveMovementSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        // update entity
        updateSearch(state, action) {
            state.search = action.payload;
        },

        getEmployee(state, action) {
          return {
              ...state,
              employee: state.employees.find((employee) => employee.id == action.payload),
          };
        },
    },
    extraReducers: (builder) => {
        builder
            // Add case for fetching annual leave data
            .addCase(getAnnualLeave.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getAnnualLeave.fulfilled, (state, action) => {
                state.loading = false;
                state.annualLeave = action.payload;
            })
            .addCase(getAnnualLeave.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
                console.error(action.payload);
            })

            // Add case for fetching sick leave data
            .addCase(getSickLeave.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getSickLeave.fulfilled, (state, action) => {
                state.loading = false;
                state.sickLeave = action.payload; // Update sick leave records
            })
            .addCase(getSickLeave.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
                console.error(action.payload);
            })

            // Add case for fetching other leave data
            .addCase(getSpecialLeave.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getSpecialLeave.fulfilled, (state, action) => {
                state.loading = false;
                state.specialLeave = action.payload; // Update sick leave records
            })
            .addCase(getSpecialLeave.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
                console.error(action.payload);
            })

            // Add case for fetching sick leave data
            .addCase(getOtherLeave.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getOtherLeave.fulfilled, (state, action) => {
                state.loading = false;
                state.otherLeave = action.payload; // Update sick leave records
            })
            .addCase(getOtherLeave.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
                console.error(action.payload);
            })

            // Add case for fetching sick leave data
            .addCase(getUnpaidLeave.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getUnpaidLeave.fulfilled, (state, action) => {
                state.loading = false;
                state.unpaidLeave = action.payload; // Update sick leave records
            })
            .addCase(getUnpaidLeave.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
                console.error(action.payload);
            })

            // Add case for fetching sick leave data
            .addCase(getMedicalBenefits.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getMedicalBenefits.fulfilled, (state, action) => {
                state.loading = false;
                state.medicalBenefits = action.payload; // Update sick leave records
            })
            .addCase(getMedicalBenefits.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
                console.error(action.payload);
            })

            // Add case for fetching sick leave data
            .addCase(getMaternityLeave.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getMaternityLeave.fulfilled, (state, action) => {
                state.loading = false;
                state.maternityLeave = action.payload; // Update sick leave records
            })
            .addCase(getMaternityLeave.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
                console.error(action.payload);
            })

            // Add case for fetching sick leave data
            .addCase(getHospitalizationLeave.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getHospitalizationLeave.fulfilled, (state, action) => {
                state.loading = false;
                state.hospitalizationLeave = action.payload; // Update sick leave records
            })
            .addCase(getHospitalizationLeave.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
                console.error(action.payload);
            })

            // Add case for fetching sick leave data
            .addCase(postLeaveMovementReportDownload.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(postLeaveMovementReportDownload.fulfilled, (state, action) => {
                state.loading = false;
                //state.records = action.payload;
            })
            .addCase(postLeaveMovementReportDownload.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
                console.error(action.payload);
            })

            // Add case for fetching sick leave data
            .addCase(postLeaveMovementReportDownloadPDF.pending, (state, action) => {
              state.loading = true;
            })
            .addCase(postLeaveMovementReportDownloadPDF.fulfilled, (state, action) => {
                state.loading = false;
                //state.records = action.payload;
            })
            .addCase(postLeaveMovementReportDownloadPDF.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
                console.error(action.payload);
            })                        

            // post search
            .addCase(postsearchText.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(postsearchText.fulfilled, (state, action) => {
                state.loading = false; // Set loading to false
                state.employees = action.payload; // Update employees array with search results
                state.error = false; // Reset error to false
            })
            .addCase(postsearchText.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
                console.error(action.payload);
            })

            //Details
            .addCase(getLeaveApplicationDetails.pending, (state) => {
              state.loading = true;
              state.error = false;
            })
            .addCase(getLeaveApplicationDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.details = action.payload;
            })
            .addCase(getLeaveApplicationDetails.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            //Details
            .addCase(getAEmployee.pending, (state) => {
              state.loading = true;
              state.error = false;
            })
            .addCase(getAEmployee.fulfilled, (state, action) => {
                state.loading = false;
                state.employee = action.payload;
            })
            .addCase(getAEmployee.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
    },
});

export const { updateSearch, getEmployee } = LeaveMovementSlice.actions;

export default LeaveMovementSlice.reducer;