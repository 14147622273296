import { useNavigate } from "react-router-dom";
import { Container, Card, Table, ButtonGroup, Button, Col, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  faMoneyBill, 
  faPenToSquare, 
  faChevronRight, 
  faChevronLeft,
  faHourglass,
  faCheck,
  faReply,
  faMinus,
  faClose
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import { getLeaveApplicationListByUserID } from "../../3.Store/ApplicationSlice";
import { useEffect, useState } from "react";
import { ThreeDots } from 'react-loader-spinner';
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const LeaveApplicationList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const employee = JSON.parse(localStorage.getItem("current_user"));
  const userId = employee?.id;

  useEffect(() => {
    setLoading(true);
    dispatch(getLeaveApplicationListByUserID({ id: userId }))
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [userId]);

  const { applicationLists } = useSelector((store) => store.ApplicationSlice);

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 20; // Number of items per page
  const totalPages = Math.ceil(applicationLists.length / pageSize);

  const handleBtnBack = () => {
    navigate(-1);
  };

  const handleBtnAdd = () => {
    navigate("/LeaveApplicationForm");
  };

  const handleBtnEdit = (id) => {
    navigate(`/LeaveApplicationForm/${id}`);
  };

  const handleBtnMedical = (leaveApplicationId) => {
    navigate(`/MedicalClaimForm/leave/${leaveApplicationId}`);
  };

  const handlePreviousPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };

  const renderPaginationControls = () => (
    <Col style={{ marginLeft: "auto" }}>
      <Form.Label className="FormLabel">{`${currentPage} of ${totalPages}`}</Form.Label> &ensp;
      {currentPage > 1 ? (
        <Form.Label className="FormLabel" onClick={handlePreviousPage}>
          <FontAwesomeIcon icon={faChevronLeft} style={{ color: "#ffffff" }} />
        </Form.Label>
      ) : (
        <Form.Label className="FormLabel" style={{ color: "#cccccc" }}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </Form.Label>
      )}
      &ensp;
      {currentPage < totalPages ? (
        <Form.Label className="FormLabel" onClick={handleNextPage}>
          <FontAwesomeIcon icon={faChevronRight} style={{ color: "#ffffff" }} />
        </Form.Label>
      ) : (
        <Form.Label className="FormLabel" style={{ color: "#cccccc" }}>
          <FontAwesomeIcon icon={faChevronRight} />
        </Form.Label>
      )}
    </Col>
  );

  const truncateText = (text, maxWords) => {
    if (!text) return '';
    const words = text.split(' ');
    if (words.length <= maxWords) return text;
    return words.slice(0, maxWords).join(' ') + '...';
  }

  return (
    <Container>
      {loading && (
        <ThreeDots
          visible={loading}
          height="80"
          width="80"
          color="#4fa94d"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: '9999',
          }}
        />
      )}
      <Card className="CardContent">
        <h1 className="TitleHeader">Leave Applications</h1>
        <h1 className="MiniTitleHeader">List</h1>

        {renderPaginationControls()}

        <Table>
          <thead>
            <tr style={{cursor:"pointer"}}>
              <th>Date</th>
              <th>Type</th>
              <th>Description</th>
              <th>Reason</th>
              <th>Status</th>
              <th>
                <FontAwesomeIcon icon={faMoneyBill} />
              </th>
              <th>
                <FontAwesomeIcon icon={faPenToSquare} />
              </th>
            </tr>
          </thead>

          <tbody>
            {!loading && applicationLists.length > 0 ? (
              applicationLists.slice((currentPage - 1) * pageSize, currentPage * pageSize).map(
                ({ id, created, leave_type, description, reason_for_application, status }) => (
                  <tr key={id} style={{cursor:"pointer"}}>
                    <td style={{ width:'15%' }}>{moment(created).format("YYYY-MM-DD")}</td>
                    <td style={{ width:'15%' }}>{leave_type?.description}</td>
                    <td style={{ width:'25%' }}>{truncateText(description, 4)}</td>
                    <td style={{ width:'25%' }}>{truncateText(reason_for_application, 4)}</td>
                    <td style={{ width:'10%' }}>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>{status?.short_description}</Tooltip>}
                      >
                        <span>
                          {status?.short_description === "PENDING" && (
                            <FontAwesomeIcon icon={faHourglass} color="#FFC107" />
                          )}
                          {status?.short_description === "APPROVED" && (
                            <FontAwesomeIcon icon={faCheck} color="#28A744" />
                          )}
                          {status?.short_description === "APPROVED-HR" && (
                            <FontAwesomeIcon icon={faCheck} color="#28A744" />
                          )}
                          {status?.short_description === "REJECTED-SUPERVISOR" && (
                            <FontAwesomeIcon icon={faClose} color="#DC3545" />
                          )}
                          {status?.short_description === "REJECTED-HR" && (
                            <FontAwesomeIcon icon={faClose} color="#DC3545" />
                          )}
                          {status?.short_description === "DELETED" && (
                            <FontAwesomeIcon icon={faMinus} color="#FFC107" />
                          )}
                          {status?.short_description === "WITHDRAWN (HR)" && (
                            <FontAwesomeIcon icon={faReply} color="#28A744" />
                          )}
                          {status?.short_description === "WITHDRAWING" && (
                            <FontAwesomeIcon icon={faReply} color="#FFC107" />
                          )}
                        </span>
                      </OverlayTrigger>
                    </td>
                    <td style={{ width:'7%' }}>
                      {leave_type?.description === "Sick Leave" && (
                        <FontAwesomeIcon icon={faMoneyBill} onClick={()=>handleBtnMedical(id)}/>
                      )}
                    </td>
                    <td style={{ width:'5%' }} onClick={()=>handleBtnEdit(id)}>
                      <FontAwesomeIcon icon={faPenToSquare} />
                    </td>
                  </tr>
                )
              )
            ) : (
              <tr>
                <td colSpan="7">No results found</td>
              </tr>
            )}
          </tbody>
        </Table>

        <table>
          <tbody>
            <tr>
              <td className="FormLabel">
                <FontAwesomeIcon icon={faHourglass} color="#FFC107" />
                &nbsp;Pending
                &ensp;&ensp;&ensp;&ensp;&ensp;
                <FontAwesomeIcon icon={faMinus} color="#FFC107" />
                &nbsp;Deleted
                &ensp;&ensp;&ensp;&ensp;&ensp;
                <FontAwesomeIcon icon={faClose} color="#DC3545" />
                &nbsp;Rejected
                &ensp;&ensp;&ensp;&ensp;&ensp;
                <FontAwesomeIcon icon={faCheck} color="#28A744" />
                &nbsp;Approved
              </td>
            </tr>
            <tr>
              <td className="FormLabel">
                <FontAwesomeIcon icon={faReply} color="#28A744" />
                &nbsp;Withdrawn
                &ensp;&ensp;&ensp;&ensp;&ensp;
                <FontAwesomeIcon icon={faReply} color="#FFC107" />
                &nbsp;Withdrawing
                &ensp;&ensp;&ensp;&ensp;&ensp;
                <FontAwesomeIcon icon={faReply} color="#DC3545" />
                &nbsp;Withdrawal Rejected
              </td>
            </tr>
          </tbody>
        </table>

        {renderPaginationControls()}

        <div className="d-flex justify-content-between">
          <ButtonGroup className="my-2">
            <Button className="Button" onClick={handleBtnBack}>
              Back
            </Button>
          </ButtonGroup>
          <ButtonGroup className="my-2">
            <Button className="Button" onClick={handleBtnAdd}>
              Add
            </Button>
          </ButtonGroup>
        </div>
      </Card>
    </Container>
  );
};

export default LeaveApplicationList;