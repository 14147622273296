import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Card,
  Row,
  Col,
  Form,
  ButtonGroup,
  Button,
} from "react-bootstrap";
import moment from "moment/moment";
import { ThreeDots } from 'react-loader-spinner';
import { getLeaveSummaryEmployeeLastYear, getLeaveAwardedThisYear, getListsBeforeForfeit } from "../../3.Store/LeaveAccrualYearEndSlice";

const LeaveAccrualYearEndList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const summariesLastYear = useSelector(state => state.LeaveAccrualYearEndSlice.summaries);
  const awarded = useSelector(state => state.LeaveAccrualYearEndSlice.awarded);
  const bfForfeit = useSelector(state => state.LeaveAccrualYearEndSlice.bfForfeit);
  const lastYear = moment().subtract(1, 'year').format('YYYY');
  const [excessLeave, setexcessLeave] = useState();

  const user = JSON.parse(localStorage.getItem("current_user"));
  const name = user?.fullname;

  useEffect(() => {
    if(name) {
      setLoading(true);
      dispatch(getLeaveSummaryEmployeeLastYear({id: user.id, year: lastYear}));
      dispatch(getLeaveAwardedThisYear({id: user.id, year: moment().format('YYYY')}));
      dispatch(getListsBeforeForfeit({id: user.id, year: moment().format('YYYY')}))
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    };
  }, [name]);

  useEffect(() => {
    // Calculation
    const leaveEntitlement = Number(awarded?.annual);
    const leaveBalance = Number(summariesLastYear[0]?.cf);
    const leaveBalanceBefore15CNY = Number(bfForfeit[0]?.cf);

    const leaveUtilized = leaveEntitlement + leaveBalance - leaveBalanceBefore15CNY;
    const exLeave = leaveBalance - leaveUtilized - 5;

    if (exLeave <= 0) {
      setexcessLeave(0);
    }
    else {
      setexcessLeave(leaveBalance - leaveUtilized - 5);
    }
  }, [summariesLastYear, awarded, bfForfeit])

  const handleBtnApplyLeave = () => {
    navigate("/LeaveApplicationForm");
  };

  const handleBtnBack = () => {
    navigate(-1);
  };

  return (
    <Container>
      {loading && (
          <ThreeDots
          visible={loading}
          height="80"
          width="80"
          color="#4fa94d"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{
              position: 'fixed',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: '9999',
          }}
          />
      )}
      <Card className="CardContent">
        <h1 className="TitleHeader">Leave Accrual</h1>

        <Row className="my-3">
          <Col>
            <Row className="my-3">
              <Col>
                <h2 className="FormLabel">{name}</h2>
              </Col>
            </Row>

            <hr style={{ borderTop: "3px solid white" }}/>

            <Row className="my-3">
              <Col>
                <h2 className="FormLabel">
                  {/* Balance as of {moment().format('MMM D, YYYY')} */}
                  Balance as of <font color="#33ffc9">{moment().format('MMM D, YYYY')}</font>
                </h2>
              </Col>
            </Row>

            <br/>

            <Row className="my-3">
              <Col>
                <font size="5" className="FormLabel">
                  Leave Balance ({lastYear}): <font size="6">{summariesLastYear[0]?.cf}</font>
                </font>
              </Col>
            </Row>

            <br/>

            <Row className="my-3">
              <Col>
                <font size="5" className="FormLabel">
                  Leave Awarded ({moment().format('YYYY')}): <font size="6">{awarded?.annual}</font>
                </font>
              </Col>
            </Row>

            <br/>

            <Row className="my-3">
              <Col>
                <font size="5" className="FormLabel">
                  Starting Balance ({moment().format('YYYY')}):{' '}
                  <font size="6">{(Number(summariesLastYear[0]?.cf) + Number(awarded?.annual))}</font>
                </font>
              </Col>
            </Row>

            <br/>

            <Row className="my-3">
              <Col>
                <font size="5" className="FormLabel">
                  Excess Leave To Be Cleared by 28 Feb 2025: <font color="yellow" size="6">{excessLeave}</font>
                </font>
              </Col>
            </Row>

            <br/>

            <Row className="my-3">
              <Col>
                <font size="5" className="FormLabel">
                  Note: Excess leave not cleared by the specified date will be forfeited.
                </font>
              </Col>
            </Row>
          </Col>
        </Row>

        <div className="d-flex justify-content-between my-2">
          <ButtonGroup>
            <Button className="Button" onClick={handleBtnBack}>
              Back
            </Button>
          </ButtonGroup>
          <ButtonGroup>
            <Button className="Button" onClick={handleBtnApplyLeave}>
              Apply Leave
            </Button>
          </ButtonGroup>
        </div>
      </Card>
    </Container>
  );
};
export default LeaveAccrualYearEndList;