import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import moment from "moment/moment";
import { triggerBase64Download } from 'common-base64-downloader-react';
import {
  Container,
  Card,
  Form,
  Table,
  ButtonGroup,
  Button,
  InputGroup,
  Col,
  Row
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faBoltLightning,
  faClose,
  faCheck,
  faHourglass,
  faChevronRight,
  faChevronLeft,
  faReply
} from "@fortawesome/free-solid-svg-icons";
import { 
  updateCurrentPage, 
  downloadReviewMedicalClaims,
  updateSearch,
  searchReviewClaimsPagination,
  searchReviewClaimsPageCount
} from "../../3.Store/ReviewMedicalClaimsSlice";
import { ThreeDots } from 'react-loader-spinner';
import './styles.css';
import Swal from 'sweetalert2';
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const HRReviewMedicalList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const medicalClaims = useSelector(state => state.ReviewMedicalClaimsSlice.lists) || [];
  const currentPage = useSelector(state => state.ReviewMedicalClaimsSlice.currentPage);
  const totalPages = useSelector(state => state.ReviewMedicalClaimsSlice.totalPages);
  const search = useSelector((state) => state.ReviewMedicalClaimsSlice.search);
  const { searchText, fromDate, toDate } = search;
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   setLoading(true);
  //   dispatch(getReviewClaimsPagination({ currentPage }))
  //   .then(() => {
  //     dispatch(getReviewClaimsPageCount());
  //     setLoading(false);
  //   })
  //   .catch(() => {
  //     setLoading(false);
  //   });
  // }, [currentPage, totalPages])

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const page = queryParams.get('currentPage') || 1;
    const searchText = queryParams.get('searchText') || '';
    const fromDate = queryParams.get('fromDate') || '';
    const toDate = queryParams.get('toDate') || '';
    dispatch(updateCurrentPage(parseInt(page)));
    setLoading(true);
    if (searchText) {
      const entity = { ...search, 
        searchText: searchText,
        fromDate: fromDate,
        toDate: setToDateToOneDayAhead(toDate)
      };
      //dispatch(updateSearch(entity));
      // If there is searchText, dispatch searchReviewClaimsPagination
      dispatch(searchReviewClaimsPagination({ currentPage: parseInt(page), entity: { ...entity } }))
        .then(() => {
          dispatch(searchReviewClaimsPageCount(entity));
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      const entity = { ...search, 
        searchText: "",
        fromDate: search.fromDate,
        toDate: setToDateToOneDayAhead(search.toDate)
      };
      // If there is no searchText, fetch the regular pagination data
      dispatch(searchReviewClaimsPagination({ currentPage: parseInt(page), entity: { ...entity } }))
        .then(() => {
          dispatch(searchReviewClaimsPageCount(entity));
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [location.search]); // Only location.search is a dependency  

  const handleDateChange = (e) => {
    const entity = { ...search, [e.target.name]: e.target.value };
    console.log(entity);
    dispatch(updateSearch(entity));
  };

  const handleSearch = (e) => {
    const entity = {...search, searchText: e.target.value}
    dispatch(updateSearch(entity));
  }

  const setToDateToOneDayAhead = (toDate) => {
    // Create a Date object from the input toDate string
    const date = new Date(toDate);
    
    // Add one day to the date
    date.setDate(date.getDate() + 1);
    
    // Format the date back to YYYY-MM-DD
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    
    return `${year}-${month}-${day}`;
  };
  
  const handleBtnSearch = () => {
    const entity = { ...search, toDate: setToDateToOneDayAhead(search.toDate) };
    setLoading(true);
    dispatch(updateCurrentPage(1));
      dispatch(searchReviewClaimsPagination({ currentPage: 1, entity }))// Pass searchText as part of entity
      .then(() => {
        dispatch(searchReviewClaimsPageCount(entity));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleBtnSearch();
    }
  };

  const handleBtnDownload = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "Download report from " + fromDate + " to " + toDate,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const entity = { ...search, toDate: setToDateToOneDayAhead(search.toDate) };
        const report = dispatch(downloadReviewMedicalClaims(entity));
        report.then((data) => {
            try {
              // Ensure the payload is a string
              if (typeof data.payload !== 'string') {
                  throw new Error('Invalid payload format');
              }
              // Decode the base64-encoded data
              const base64EXCEL = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data.payload}`;
              // Get current date and time
              const now = new Date();
              const day = String(now.getDate()).padStart(2, '0');
              const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
              const year = now.getFullYear();
              const hours = String(now.getHours()).padStart(2, '0');
              const minutes = String(now.getMinutes()).padStart(2, '0');
              const seconds = String(now.getSeconds()).padStart(2, '0');
              
              // Create filename with date and time
              const filename = `Medical_Claims_${day}-${month}-${year}--${hours}-${minutes}-${seconds}`;
              
              // Trigger download
              triggerBase64Download(base64EXCEL, filename);
            } catch (error) {
              console.error('Error handling download:', error);
            } finally {
              setLoading(false); // Set loading to false after data fetching completes
            }
        }).catch((error) => {
          console.error('Error fetching report data:', error);
          setLoading(false); // Set loading to false if an error occurs
        });
      }
    });
  };


  const handleBtnBack = () => {
    navigate(-1);
  };

  const handleClickView = (medicalId) => {
    navigate(`/HRReviewMedicalForm/${medicalId}?currentPage=${currentPage}&searchText=${searchText}&fromDate=${fromDate}&toDate=${toDate}`);
  };  

  const handleCheckChange = (e, id) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedCheckboxes(prevState => [...prevState, id]);
    } else {
      setSelectedCheckboxes(prevState => prevState.filter(item => item !== id));
    }
  };

  const handlePreviousPage = () => {
    setLoading(true);
    if (currentPage > 1) {
      dispatch(updateCurrentPage(currentPage - 1));
      const entity = { ...search };
        dispatch(searchReviewClaimsPagination({ currentPage: currentPage - 1, entity }))
        .then(() => {
          dispatch(searchReviewClaimsPageCount(entity));
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const handleNextPage = () => {
    setLoading(true);
    if (currentPage < totalPages) {
      dispatch(updateCurrentPage(currentPage + 1));
      const entity = { ...search };
        dispatch(searchReviewClaimsPagination({ currentPage: currentPage + 1, entity }))
        .then(() => {
          dispatch(searchReviewClaimsPageCount(entity));
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const renderStaffList = () => {
    return medicalClaims.map(claim => {
      // const rowStyle = claim?.employee?.service_length?.id === "P" ? { backgroundColor: 'pink !important' } : {};
      const rowStyle = claim.employee.status.id === 2 ? { backgroundColor: '#ff7047' } : claim.employee.service_length.id === "P" ? { backgroundColor: 'lightblue' } : '';

      return (
      <tr key={claim.id} 
      style={{ 
        cursor: 'pointer'        
        // ...rowStyle 
      }}>
          
        <td style={{...rowStyle}} onClick={()=>handleClickView(claim.id)}>{moment(claim.created).format("YYYY-MM-DD")}</td>
        <td style={{...rowStyle}} onClick={() => handleClickView(claim.id)}>
            {claim.employee.service_length.id === "P" && (
                <span className="custom-tooltip" data-toggle={"Date Joined: "+moment(claim?.employee?.date_joined).format("YYYY-MM-DD")}><b>*&nbsp;</b> </span>
            )}
            {claim.employee.status.id === 2 && (
                // <span className="custom-tooltip" data-toggle={"Date Leaving: "+moment(claim?.employee?.date_left).format("YYYY-MM-DD")} title={"Date Leaving: "+moment(claim?.employee?.date_left).format("YYYY-MM-DD")}><b>**</b> </span>              
                <span className="custom-tooltip" data-toggle={"Date Leaving: "+moment(claim?.employee?.date_left).format("YYYY-MM-DD")}><b>**</b> </span>              
            )}
            {claim.employee.fullname}
        </td>
        <td style={{...rowStyle}} onClick={()=>handleClickView(claim.id)}>{claim.receipt_no}</td>
        <td style={{...rowStyle}} onClick={()=>handleClickView(claim.id)}>{claim.receipt_reference}</td>
        <td style={{...rowStyle}} onClick={()=>handleClickView(claim.id)}>{claim.amount}</td>
        <td style={{...rowStyle}}>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{claim.status.description}</Tooltip>}
          >
            <span>
              {claim.status.description == "APPROVED-HR" && (
                <FontAwesomeIcon icon={faCheck} style={{color: "#3fa64b",}} />
              )}
              {claim.status.description == "APPROVED-S" && (
                <FontAwesomeIcon icon={faCheck} style={{color: "#3fa64b",}} />
              )}
              {claim.status.description == "PENDING" && (
                <FontAwesomeIcon icon={faHourglass} style={{color: "#FFD43B",}} />
              )}
              {claim.status.description == "WITHDRAWN" && (
                <FontAwesomeIcon icon={faReply} style={{color: "#FFD43B",}} />
              )}
              {claim.status.description == "REJECTED-HR" && (
                <FontAwesomeIcon icon={faClose} style={{color: "#f22c2c",}} />
              )}
              {claim.status.description == "REJECTED-S" && (
                <FontAwesomeIcon icon={faClose} style={{color: "#f22c2c",}} />
              )}
            </span>
          </OverlayTrigger>
        </td>

        <td style={{...rowStyle}}>
          {claim.hr_reviewed == false && (
            <FontAwesomeIcon icon={faClose} style={{color: "#f22c2c",}} />
          )}
          {claim.hr_reviewed == true && (
            <FontAwesomeIcon icon={faCheck} style={{color: "#3fa64b",}} />
          )}
        </td>
        
        {/* <td style={{...rowStyle}}>
          <Form.Check
            type="checkbox"
            id={`checkbox-${claim.id}`}
            name={`checkbox-${claim.id}`}
            onChange={(e) => handleCheckChange(e, claim.id)}
            checked={selectedCheckboxes.includes(claim.id)}
          />
        </td> */}
      </tr>
    )});
  };

  useEffect(() => {
    console.log(selectedCheckboxes);
  }, [selectedCheckboxes]);

  return (
    <Container>
      {loading && (
        <ThreeDots
          visible={loading}
          height="80"
          width="80"
          color="#4fa94d"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: '9999',
          }}
        />
      )}
      <Card className="CardContent">
        <h1 className="TitleHeader">HR Review Medical Claims</h1>
        <h1 className="MiniTitleHeader">List</h1>

        <Row className="justify-content-center my-3">
          <Col md={5} sm="auto">
            <InputGroup className="mb-3">
              <Form.Control 
                placeholder="Enter Employee Name" 
                value={searchText} 
                onChange={handleSearch} 
                onKeyPress={handleKeyPress}
              />
            </InputGroup>
          </Col>
          <Col md="auto" sm="auto">
            <Form.Label className="FormLabel">From Date:</Form.Label>
          </Col>
          <Col md="auto" sm="auto">
            <Form.Control type="date" name="fromDate" value={fromDate} onChange={handleDateChange}/>
          </Col>
          <Col md="auto" sm="auto">
            <Form.Label className="FormLabel">To Date:</Form.Label>
          </Col>
          <Col md="auto" sm="auto">
            <Form.Control type="date" name="toDate" value={toDate} onChange={handleDateChange}/>
          </Col>
          <Col md="auto" sm="auto">
            <ButtonGroup>
              <Button className="Button" onClick={handleBtnSearch}>
                Search
              </Button>
            </ButtonGroup>
          </Col>
        </Row>

        <Col style={{ marginLeft: "auto" }}>
          <Form.Label className="FormLabel">{`${currentPage} of ${totalPages}`}</Form.Label> &ensp;
          {currentPage > 1 ? (
            <Form.Label className="FormLabel" onClick={handlePreviousPage}>
              <FontAwesomeIcon icon={faChevronLeft} style={{ color: "#ffffff" }} />
            </Form.Label>
          ) : (
            <Form.Label className="FormLabel" style={{ color: "#cccccc" }}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </Form.Label>
          )}
          &ensp;
          {currentPage < totalPages ? (
            <Form.Label className="FormLabel" onClick={handleNextPage}>
              <FontAwesomeIcon icon={faChevronRight} style={{ color: "#ffffff" }} />
            </Form.Label>
          ) : (
            <Form.Label className="FormLabel" style={{ color: "#cccccc" }}>
              <FontAwesomeIcon icon={faChevronRight} />
            </Form.Label>
          )}
        </Col>

        <ButtonGroup className="my-2 ms-auto">
          <Button className="Button" onClick={handleBtnDownload}>
            Download
          </Button>
        </ButtonGroup>

        <Table>
          <thead>
            <tr>
              <th>Date Applied</th>
              <th>Employee</th>
              <th>Practitioner</th>
              <th>Receipt No.</th>
              <th>Claim</th>
              <th>Status</th>
              <th>
                <FontAwesomeIcon icon={faEye} />
              </th>
              {/* <th>
                <FontAwesomeIcon icon={faBoltLightning} />
              </th> */}
            </tr>
          </thead>
          {!loading && (
            <tbody>
              {medicalClaims.length > 0 ? (
                renderStaffList()
              ) : (
                <tr>
                  <td colSpan="8">No results found</td>
                </tr>
              )}
            </tbody>
          )}
        </Table>

        <span style={{color: "white"}}>* - Probation, ** - Resigning</span>

        <Col style={{ marginLeft: "auto" }}>
          <Form.Label className="FormLabel">{`${currentPage} of ${totalPages}`}</Form.Label> &ensp;
          {currentPage > 1 ? (
            <Form.Label className="FormLabel" onClick={handlePreviousPage}>
              <FontAwesomeIcon icon={faChevronLeft} style={{ color: "#ffffff" }} />
            </Form.Label>
          ) : (
            <Form.Label className="FormLabel" style={{ color: "#cccccc" }}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </Form.Label>
          )}
          &ensp;
          {currentPage < totalPages ? (
            <Form.Label className="FormLabel" onClick={handleNextPage}>
              <FontAwesomeIcon icon={faChevronRight} style={{ color: "#ffffff" }} />
            </Form.Label>
          ) : (
            <Form.Label className="FormLabel" style={{ color: "#cccccc" }}>
              <FontAwesomeIcon icon={faChevronRight} />
            </Form.Label>
          )}
        </Col>

        <div className="d-flex justify-content-between">
          <ButtonGroup className="my-2">
            <Button className="Button" onClick={handleBtnBack}>
              Back
            </Button>
          </ButtonGroup>
          &nbsp; &nbsp;
          <ButtonGroup className="my-2">
            <Button className="Button" onClick={handleBtnDownload}>
              Download
            </Button>
          </ButtonGroup>
        </div>
      </Card>
    </Container>
  );
};
export default HRReviewMedicalList;