import { useNavigate } from "react-router-dom";
import { Container, Card, Table, ButtonGroup, Button, Col, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { downloadLeaveAccrualsListsByYear, getLeaveAccrualsListsByYear } from "../../3.Store/LeaveAccrualSlice";
import moment from "moment/moment";
import { triggerBase64Download } from 'common-base64-downloader-react';
import { ThreeDots } from 'react-loader-spinner';

const LeaveAccrualList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accrualLists = useSelector(state => state.LeaveAccrualSlice.accrualLists);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(getLeaveAccrualsListsByYear({year: selectedYear}))
    .then(() => {
      setLoading(false);
    })
    .catch(() => {
      setLoading(false);
    });
  }, [selectedYear]);

  const handleBtnDownload = () => {
    setLoading(true);
    const report = dispatch(downloadLeaveAccrualsListsByYear({ year: selectedYear }));
    report.then((data) => {
        try {
            // Ensure the payload is a string
            if (typeof data.payload !== 'string') {
                throw new Error('Invalid payload format');
            }

            // Decode the base64-encoded data
            const base64EXCEL = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data.payload}`;
            
            // Trigger download
            triggerBase64Download(base64EXCEL, "LeaveAccrualReport_" + selectedYear);
        } catch (error) {
            console.error('Error handling download:', error);
        }
        finally {
          setLoading(false); // Set loading to false after data fetching completes
        }
    }).catch((error) => {
        console.error('Error fetching report data:', error);
    });
  };

  const handleBtnback = () => {
    navigate(-1);
  };

  const handleClickEdit = (id) => {
    navigate(`/LeaveAccrualForm/${id}`);
  };

  const handleYear = (e) => {
    setSelectedYear(e.target.value);
  };

  // Function to generate options for the dropdown menu
  const renderYearOptions = () => {
    const endYear = new Date().getFullYear() + 1; // One year ahead
    const startYear = endYear - 4;
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
        years.push(
            <option key={year} value={year}>{year}</option>
        );
    }
    return years;
  };

  return (
    <Container>
      {loading && (
        <ThreeDots
          visible={loading}
          height="80"
          width="80"
          color="#4fa94d"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: '9999',
          }}
        />
      )}
      <Card className="CardContent">
        <h1 className="TitleHeader">Leave Accruals</h1>

        <Col md={1} sm={1} style={{ marginLeft: "auto" }}>
          {/* Dropdown menu for selecting the year */}
          <Form.Select value={selectedYear} onChange={handleYear}>
              {renderYearOptions()}
          </Form.Select>
        </Col>

        <div className="d-flex justify-content-between">
          <h1 className="MiniTitleHeader">List</h1>
    
          <ButtonGroup className="my-2">
            <Button className="Button" onClick={handleBtnDownload}>
              Download
            </Button>
          </ButtonGroup>
        </div>

        <Table>
          <thead>
            <tr>
              <th className="align-middle col-xs-2" rowSpan="2">Employee</th>
              <th className="align-middle col-xs-2" rowSpan="2">Year</th>
              <th className="align-middle col-xs-2" rowSpan="2">Balance as of 31 Dec&nbsp;{selectedYear - 1}</th>
              <th className="align-middle col-xs-2" rowSpan="2">Bring Forward</th>
              <th className="align-middle col-xs-2" rowSpan="2">Leave Utilized before March&nbsp;{selectedYear}</th>
              <th className="align-middle col-xs-2" rowSpan="2">To be clear before March&nbsp;{selectedYear}</th>
              <th className="col-xs-2" colSpan="3">Leave Encashment</th>
              <th className="col-xs-2" colSpan="3">Leave Forfeit</th>
              <th className="text-center align-middle col-xs-2" rowSpan="2" title="Edit">
                <FontAwesomeIcon icon={faPenToSquare} />
              </th>
            </tr>
            <tr>
              <th className="small">Date</th>
              <th className="small">No of Days</th>
              <th className="small">Status</th>
              <th className="small">Date</th>
              <th className="small">No of Days</th>
              <th className="small">Status</th>
            </tr>
          </thead>
          <tbody>
            {!loading && (
              accrualLists.length > 0 ? (
                accrualLists.map(employee => (
                  <>
                  <tr style={{ cursor: "pointer" }}>
                    <td rowSpan="2">{employee.employee.fullname}</td>
                    <td rowSpan="2">{employee.year}</td>
                    <td rowSpan="2">{employee.prev_cf}</td>
                    <td rowSpan="2">{employee.bf}</td>
                    <td rowSpan="2">{employee.utilized}</td>
                    <td rowSpan="2">{employee.to_be_cleared}</td>
                  </tr>
                  <tr style={{ cursor: "pointer" }}>
                    <td>
                      {employee?.encashment?.created ? moment(employee.encashment.encashment_date).format("YYYY-MM-DD") : ""}
                    </td>
                    <td>{employee?.encashment?.day_encashment}</td>
                    <td>{employee?.encashment?.status.description}</td>
                    <td>{moment(employee?.forfeit?.accrual_date).format("YYYY-MM-DD")}</td>
                    <td>{employee?.forfeit?.forfeit}</td>
                    <td>{employee?.forfeit?.status.description}</td>
                    <td onClick={() => handleClickEdit(employee.id)}>
                      <FontAwesomeIcon icon={faPenToSquare} />
                    </td>
                  </tr>
                  </>
                ))
              ) : (
                <tr>
                  <td colSpan="13">No results found</td>
                </tr>
              )
            )}
          </tbody>
        </Table>

        <div className="d-flex justify-content-between">
          <ButtonGroup className="my-2">
            <Button className="Button" onClick={handleBtnback}>
              Back
            </Button>
          </ButtonGroup>
          &nbsp; &nbsp;
          <ButtonGroup className="my-2">
            <Button className="Button" onClick={handleBtnDownload}>
              Download
            </Button>
          </ButtonGroup>
        </div>
      </Card>
    </Container>
  );
};
export default LeaveAccrualList;
