import Swal from "sweetalert2";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faLock } from "@fortawesome/free-solid-svg-icons";

import "./LoginStyle.css";

import { postLogin, updateLogin, getRoles } from "../../3.Store/LoginSlice";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { login } = useSelector((state) => state.LoginSlice);
  const { username, password } = login;

  // useEffect(() => {    
  //   if (localStorage.getItem('current_user')) {
  //     navigate('/');
  //   }
  // }, [navigate]);  

  useEffect(() => {
    const currentUser = localStorage.getItem('current_user');

    if (currentUser) {
      navigate('/'); // Navigate to home if user exists
    }
  }, [navigate]);

  const handleChangeForm = (e) => {
    const entity = { ...login, [e.target.name]: e.target.value };
    dispatch(updateLogin(entity));
  };

  // Handle login submission
  const handleLogin = (e) => {
    // e.preventDefault();
    if (e) {
      e.preventDefault(); // Prevent default form submission if event is passed
    }
    const entity = { ...login };
    dispatch(postLogin(entity))
      .unwrap()
      .then((data) => {
        console.log(data)
        dispatch(getRoles(entity)) // Fetch user roles if needed
        .unwrap()
        .then(() => {
          window.location.href = "/";
        })
      })
      .catch((err) => {
        Swal.fire("Error / Masalah", err.response.data, "error", "OK");
      });
  };

  const handleKeyPress = (e) => {
		console.log(1212)
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <Container style={{ flex: 1 }} className="ContainerContentLogin">
      <div className="TitleContainer">
        <h5 className="TitleLabel">Leave Application</h5>
        <FontAwesomeIcon icon={faLock} className="LockIcon" />
      </div>

      <div className="FormControlContainer">
        <div className="FormControlWrapper">
          <Form.Control
            name="username"
            type="text"
            placeholder="Username"
            value={username}
            onChange={handleChangeForm}
            onKeyPress={handleKeyPress}
          />
        </div>
      </div>

      <div className="FormControlContainer">
        <div className="FormControlWrapper">
          <Form.Control
            name="password"
            type="password"
            placeholder="Password"
            value={password}
            onChange={handleChangeForm}
            onKeyPress={handleKeyPress}
          />
        </div>
      </div>

      <div>
        <Button className="BtnLogin" onClick={handleLogin}>
          Log In
        </Button>
      </div>
    </Container>
  );
};

export default Login;
