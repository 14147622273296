import React, { useEffect } from 'react';
import { Table, Tooltip, OverlayTrigger } from "react-bootstrap";
import moment from "moment";
import './LeaveReport.css';
import { useNavigate } from 'react-router-dom';

function LeaveTable({ title, leaveData = [], year, loading }) {
    const navigate = useNavigate();

    function formatDate(dateString) {
        if (!dateString) {
            return '';
        }
        return moment(dateString).format('YYYY-MM-DD');
    }

    // Calculate total DR
    const totalDR = Array.isArray(leaveData) ? leaveData.reduce((total, item) => {
        // Check if item is "LEAVE WITHDRAWN"
        if (item.leave_move_record.leave_movement_type?.description === "LEAVE WITHDRAWN") {
            // Deduct CR value to total if item is "LEAVE WITHDRAWN"
            return total - parseFloat(item.leave_move_record.cr || 0);
        } else {
            // Otherwise, add DR value to total
            return total + parseFloat(item.leave_move_record.dr || 0);
        }
    }, 0).toFixed(1) : '0.0';

    const getBackgroundColor = (item) => {
        return item.leave_move_record.leave_movement_type?.id === 'P' ? 'yellow' : 'white';
    };

    const handleViewDetails = (item) => {
        navigate(`/LeaveReportForm/${item.leave_move_record.ref_id}`);
    };

    return (
        <Table>
            <thead>
                <tr style={{ cursor: 'pointer' }}>
                    <th style={{width: '9%'}}>Date Applied</th>
                    {title === "Medical Benefits" ? <th style={{width: '15%'}}>Invoice Date</th> : <th style={{width: '16%'}}>From - To</th>}
                    <th style={{width: '13%'}}>Item</th>
                    {title === "Special Leave" && <th className="col-sm-2">Type</th>}
                    {title === "Unpaid Leave" ? (
                        <th style={{width: '30%'}}>Remark</th>
                    ) : (
                        <th style={{width: '30%'}}>Reason</th>
                    )}
                    <th style={{width: '5%'}}>CR</th>
                    <th style={{width: '5%'}}>DR</th>
                    <th style={{width: '5%'}}>CF</th>
                </tr>
            </thead>
            <tbody>
                {!loading && Array.isArray(leaveData) && leaveData.length > 0 ? (
                    leaveData.map((item, index) => (
                        <tr key={item.leave_move_record.id} style={{ cursor: 'pointer' }}>
                            <td style={{ backgroundColor: getBackgroundColor(item) }}>{item.created ? formatDate(item.created) : formatDate(item.leave_move_record?.date?.date)}</td>
                            {title === "Medical Benefits" ? (
                                <td style={{ backgroundColor: getBackgroundColor(item) }}>{formatDate(item.claim_date) || "-"}</td>
                            ) : (
                                <td style={{ backgroundColor: getBackgroundColor(item) }}>{formatDate(item.from_date)} - {formatDate(item.to_date)}</td>
                            )}
                            <td style={{ backgroundColor: getBackgroundColor(item) }}>{item.leave_move_record.leave_movement_type?.description}</td>
                            {title === "Special Leave" && <td>{item.special_leave_type}</td>}
                            <td className="ellipsis" style={{ backgroundColor: getBackgroundColor(item) }}>
                                {item.leave_move_record.leave_movement_type?.id === 'P' && item.prorate_type === -1 ? (
                                    <OverlayTrigger
                                        placement="auto"
                                        overlay={
                                            <Tooltip style={{ backgroundColor: 'black', color: 'white', width: '200px', whiteSpace: 'normal'}}>
                                                {item.date_left}
                                            </Tooltip>
                                        }
                                    >
                                        <span>
                                            {item.date_left}
                                        </span>
                                    </OverlayTrigger>
                                ) : (
                                    <OverlayTrigger
                                        placement="auto"
                                        overlay={
                                            <Tooltip style={{ backgroundColor: 'black', color: 'white', width: '200px', whiteSpace: 'normal'}}>
                                                {item.reason_for_application}
                                            </Tooltip>
                                        }
                                    >
                                        <span>
                                            {item.reason_for_application}
                                        </span>
                                    </OverlayTrigger>
                                )}
                            </td>
                            <td style={{ backgroundColor: getBackgroundColor(item) }}>{item.leave_move_record.cr}</td>
                            <td
                                onClick={() => {
                                    if (item.leave_move_record.leave_movement_type?.description === "LEAVE APPLIED") {
                                        handleViewDetails(item);
                                    }
                                }}
                                style={{ backgroundColor: getBackgroundColor(item) }}
                            >
                                {item.leave_move_record.dr}
                            </td>
                            <td style={{ backgroundColor: getBackgroundColor(item) }}>{item.leave_move_record.cf}</td>
                        </tr>
                    ))
                ) : (
                <tr>
                    <td colSpan={title === "Special Leave" ? 8 : 7}>No data available</td>
                </tr>
                )}
            </tbody>
            <tfoot>
                <tr>
                <td colSpan={title === "Special Leave" ? 5 : 4}></td>
                <td>Total: </td>
                <td>{totalDR}</td>
                <td></td>
                </tr>
            </tfoot>
        </Table>
    );
}

export default LeaveTable;