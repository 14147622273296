import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment/moment";
import {
  Container,
  Card,
  Row,
  Col,
  Form,
  ButtonGroup,
  Button,
} from "react-bootstrap";
import defaultPhoto from "../../2.Commons/defaultPhoto.jpg";
import { useDispatch, useSelector } from "react-redux";
import { ThreeDots } from 'react-loader-spinner';
import Swal from "sweetalert2";
import './Employee.css';
import { 
  updateForm,
  updateProrated, 
  getLeaveSummaryEmployeeYear, 
  getSickleaveSummaryEmployeeYear, 
  getMedicalbenefitLeaveSummaryEmployeeYear, 
  resignEmployee, 
  dismissUser,
  dismissEmployee,
  addLeaveProrated,
  printEmployee,
  resetProrated
} from "../../3.Store/EmployeeSlice";

const EmployeeResignationForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const photo = useSelector((state) => state.EmployeeSlice.photo);
  const employee = useSelector((state) => state.EmployeeSlice.employeeDetail);
  const summary = useSelector((state) => state.EmployeeSlice.summaries);
  const sickLeaveSummaries = useSelector((state) => state.EmployeeSlice.sickLeaveSummaries);
  const medicalBenefitSummaries = useSelector((state) => state.EmployeeSlice.medicalBenefitSummaries);
  const leaveProrated = useSelector((state) => state.EmployeeSlice.leaveProrated);
  const year = new Date().getFullYear();
  const { fullname, username, date_joined, date_left, status } = employee || {};
  const [loading, setLoading] = useState(false);
  const { 
    current_leave_balance,
    deduction_required,
    leave_bf,
    leave_taken_this_year,
    new_leave_balance,
    prorated_leave_awarded,
    prorated_pct_final_year } = leaveProrated;

  useEffect(() => {
    if(id) {
      setLoading(true);
      dispatch(getLeaveSummaryEmployeeYear({ id: id, year }))
      .then((dispatch(getSickleaveSummaryEmployeeYear({ id: id, year }))))
      .then((dispatch(getMedicalbenefitLeaveSummaryEmployeeYear({ id: id, year }))))
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
          setLoading(false);
      });
    }
  }, [id]);

  // useEffect(() => {
  //   if (summary && summary.length > 0) {
      
  //     var start = new Date((new Date()).getFullYear(), 0, 0);
  //     var end = new Date((new Date()).getFullYear() + 1, 0, 0);

  //     console.log(start, end);

  //     var date = moment(moment(date_left).format());
  //     // Get the timestamp in milliseconds
  //     var timestamp = date.valueOf();

  //     var diff = (timestamp - start.getTime()) / (end.getTime() - start.getTime());
  //     console.log(diff)
  //     var pct_to_be_prorated = (1.0 - diff);
  //     var prorated_leave_award2 = diff * summary[0]?.entitled;
      
  //     var new_leave_balance2 = +summary[0]?.ob + +prorated_leave_award2 - +summary[0]?.utilized;
  //     var deduction_required2 = 0;

  //     if (new_leave_balance2 < 0) {
  //         deduction_required2 = (-1) *  new_leave_balance2;
  //         new_leave_balance2 = 0;
  //     };
    
  //     var prorated_sickleave_award = (1 - diff) * sickLeaveSummaries[0]?.entitled;            
  //     var prorated_medicalbenefit_award = (1 - diff) * medicalBenefitSummaries[0]?.entitled;  

  //     const entity = { ...leaveProrated, 
  //       leave_bf: summary[0]?.ob,
  //       leave_awarded_this_year: summary[0]?.entitled,
  //       leave_taken_this_year: summary[0]?.utilized,
  //       current_leave_balance: summary[0]?.cf,
  //       prorate_type: -1,
  //       prorate_date: moment(new Date()).format("YYYY-MM-DD"),
  //       year: new Date().getFullYear(),
  //       new_leave_balance: Math.round(2 * new_leave_balance2)/2,
  //       deduction_required: Math.round(2 * deduction_required2)/2,
  //       prorated_leave_awarded: Math.round(2 * prorated_leave_award2)/2,
  //       prorated_sickleave_awarded: ((Math.round(2 * prorated_sickleave_award)/2) * -1),
  //       prorated_medicalbenefit_awarded: ((Math.round(2 * prorated_medicalbenefit_award)/2) * -1),
  //       prorated_pct_final_year: Math.round(1000 * pct_to_be_prorated)/10,
  //       employee: { id: id }
  //     }
  //     console.log(entity);
  //     dispatch(updateProrated(entity));

  //     const entity2 = { ...employee, 
  //       date_left: getDateOneMonthFromToday() 
  //     };
  //     dispatch(updateForm(entity2));
  //   }
  // }, [summary]);
  
  useEffect(() => {
    recalculateProratedLeave();
  }, [employee.date_left]);
  
  useEffect(() => {
    if (summary && summary.length > 0) {
      
      var date = moment(moment(date_left).format());
      var currentMonth = date.month() + 1;
      console.log(currentMonth);
      if (date.date() < 28 && date.date() > 12) {
        currentMonth -= 0.5;  // Add 1 month if before the 13th
      }
      else if (date.date() <= 12) {
        currentMonth -= 1;
      }

      var dateJoined = moment(date_joined); // Convert joining date to moment object
      var dateLeft = moment(date_left); // Convert leaving date to moment object

      // Get the year for both joining and leaving
      var yearJoined = dateJoined.year();
      var yearLeft = dateLeft.year();

      // Compare the years
      if (yearJoined === yearLeft) {
        var joinMonth = dateJoined.month() + 1;
        var diff = (currentMonth - joinMonth) / (12 - joinMonth);
        console.log(diff);
        var prorated_leave_award2 = Math.round(2 * (summary[0]?.cf / (12 - joinMonth)) * (12 - currentMonth))/2;
        var new_leave_balance2 = +summary[0]?.cf - +prorated_leave_award2;
      } else {
        var diff = (currentMonth) / 12;
        console.log(diff); //0.6666
        // var prorated_leave_award2 = diff * summary[0]?.entitled; // 2.5
        var prorated_leave_award2 = Math.round(2 * (summary[0]?.entitled / 12) * (12 - currentMonth))/2; // 2.5
        console.log(prorated_leave_award2)
        var new_leave_balance2 = +summary[0]?.cf - +prorated_leave_award2;
      }

      // var diff = (currentMonth) / 12;
      // console.log(diff);

      // var diff = (timestamp - start.getTime()) / (end.getTime() - start.getTime());
      var pct_to_be_prorated = (1.0 - diff);
      // var prorated_leave_award2 = diff * summary[0]?.entitled;
      // var prorated_leave_award2 = (summary[0]?.entitled / 12) * (currentMonth-1);
      console.log(prorated_leave_award2)
      // var new_leave_balance2 = +summary[0]?.ob + +prorated_leave_award2 - +summary[0]?.utilized;
      var deduction_required2 = 0;

      if (new_leave_balance2 < 0) {
          deduction_required2 = (-1) *  new_leave_balance2;
          new_leave_balance2 = 0;
      };
    
      // var prorated_sickleave_award = diff * sickLeaveSummaries[0]?.entitled;   
      var prorated_sickleave_award = (sickLeaveSummaries[0]?.entitled / 12) * (12 - currentMonth);  
      var prorated_medicalbenefit_award = (medicalBenefitSummaries[0]?.entitled / 12) * (12 - currentMonth);    

      const entity = { ...leaveProrated, 
        leave_bf: summary[0]?.ob,
        leave_taken_this_year: summary[0]?.utilized,
        current_leave_balance: summary[0]?.cf,
        prorate_type: -1,
        prorate_date: moment(new Date()).format("YYYY-MM-DD"),
        year: new Date().getFullYear(),
        new_leave_balance: Math.round(2 * new_leave_balance2)/2,
        deduction_required: Math.round(2 * deduction_required2)/2,
        // prorated_leave_awarded: Math.round(2 * prorated_leave_award2)/2,
        prorated_leave_awarded: ((Math.round(2 * prorated_leave_award2)/2) * -1),
        prorated_sickleave_awarded: ((Math.round(2 * prorated_sickleave_award)/2) * -1),
        prorated_medicalbenefit_awarded: ((Math.round(2 * prorated_medicalbenefit_award)/2) * -1),
        prorated_pct_final_year: Math.round(1000 * pct_to_be_prorated)/10,
        employee: { id: id }
      }
      console.log(entity);
      dispatch(updateProrated(entity));

      const entity2 = { ...employee, 
        date_left: getDateOneMonthFromToday() 
      };
      dispatch(updateForm(entity2));
    }
  }, [summary]);

  const recalculateProratedLeave = () => {

    var date = moment(moment(date_left).format());
    // Get the current month (0 = January, so add 1)
    var currentMonth = date.month() + 1;

    // Adjust the currentMonth based on the day of the month
    if (date.date() < 28 && date.date() > 12) {
      currentMonth -= 0.5;
    }
    else if (date.date() <= 12) {
      currentMonth -= 1;  // Minus 1 month if before the 13th
    }

    var dateJoined = moment(date_joined); // Convert joining date to moment object
    var dateLeft = moment(date_left); // Convert leaving date to moment object

    // Get the year for both joining and leaving
    var yearJoined = dateJoined.year();
    var yearLeft = dateLeft.year();

    // Compare the years
    if (yearJoined === yearLeft) {
      var joinMonth = dateJoined.month() + 1;
      var diff = (currentMonth - joinMonth) / (12 - joinMonth);
      console.log(diff);
      var prorated_leave_award2 = Math.round(2 * (summary[0]?.cf / (12 - joinMonth)) * (12 - currentMonth))/2;
      var new_leave_balance2 = +summary[0]?.cf - +prorated_leave_award2;
    } else {
      var diff = (currentMonth) / 12;
      console.log(diff); //0.6666
      // var prorated_leave_award2 = diff * summary[0]?.entitled; // 2.5
      var prorated_leave_award2 = Math.round(2 * (summary[0]?.entitled / 12) * (12 - currentMonth))/2; // 2.5
      console.log(prorated_leave_award2)
      var new_leave_balance2 = +summary[0]?.cf - +prorated_leave_award2;
    }

    // var diff = (end.getTime() - date.getTime()) / (end.getTime() - start.getTime());
    // var diff = (currentMonth - 1) / 12;

    var pct_to_be_prorated = (1.0 - diff);
    var deduction_required2 = 0;

    if (new_leave_balance2 < 0) {
        deduction_required2 = (-1) *  new_leave_balance2;
        new_leave_balance2 = 0;
    };

    var prorated_sickleave_award = (sickLeaveSummaries[0]?.entitled / 12) * (12 - currentMonth);  
    var prorated_medicalbenefit_award = (medicalBenefitSummaries[0]?.entitled / 12) * (12 - currentMonth);  

    const entity = { ...leaveProrated, 
      new_leave_balance: Math.round(2 * new_leave_balance2)/2,
      deduction_required: Math.round(2 * deduction_required2)/2,
      prorated_leave_awarded: ((Math.round(2 * prorated_leave_award2)/2) * -1),
      prorated_sickleave_awarded: ((Math.round(2 * prorated_sickleave_award)/2) * -1),
      prorated_medicalbenefit_awarded: ((Math.round(2 * prorated_medicalbenefit_award)/2) * -1),
      prorated_pct_final_year: Math.round(1000 * pct_to_be_prorated)/10,
    }
    dispatch(updateProrated(entity));
  };

  // Calculate the date one month from today
  const getDateOneMonthFromToday = () => {
    return moment().add(1, 'months').format("YYYY-MM-DD");
  };

  const handleDate = (e) => {
    const entity = { ...employee, date_left: e.target.value }
    console.log(entity);
    dispatch(updateForm(entity));
  };

  // const handleBtnPrint = () => {
  //   dispatch(printEmployee({id}));
  // };

  const handleBtnSubmit = () => {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const entity = { ...employee, 
          date_joined: moment(employee.date_joined).format("YYYY-MM-DD"),
          status: { id: 2 } 
        };
        dispatch(updateForm(entity));
        dispatch(addLeaveProrated({ date_left: employee.date_left, ...leaveProrated }))
          .unwrap()
          .then(() => {
            return dispatch(resignEmployee({ id: employee.id, date_left: employee.date_left })).unwrap();
          })
          .then(() => {
            return dispatch(dismissEmployee({ id: employee.id, ...entity })).unwrap();
          })
          .then(() => {
            return dispatch(dismissUser({ id: employee.id, status: { id: 1 } })).unwrap();
          })
          .then(() => {
            setLoading(false);
            Swal.fire({
              title: fullname + ' has resigned',
              icon: 'success',
              confirmButtonText: 'OK'
            }).then((result) => {
              if (result.isConfirmed) {
                navigate(-1);
              }
            });
          })
          .catch((err) => {
            setLoading(false);
            Swal.fire("Error", err.response.data, "error");
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Cancel Resign Employee', 'error');
      }
    });
  };

  const handleBtnCancel = () => {
    dispatch(resetProrated());
    navigate(-1);
  };

  const handleChangeLeave = (e) => {
    const updatedValue = e.target.value;
    const entity = { ...leaveProrated, [e.target.name]: updatedValue };

    //Recalculate with the updated value
    // let start = new Date(new Date().getFullYear(), 0, 0);
    // let end = new Date(new Date().getFullYear() + 1, 0, 0);
    // let date = moment(date_left);
    // let timestamp = date.valueOf();
    // let diff = (timestamp - start.getTime()) / (end.getTime() - start.getTime());

    var date = moment(moment(date_left).format());
    var currentMonth = date.month() + 1;
    console.log(currentMonth);

    // Adjust the currentMonth based on the day of the month
    if (date.date() < 28 && date.date() > 12) {
      currentMonth -= 0.5;  // Add 1 month if before the 13th
    }
    else if (date.date() <= 12) {
      currentMonth -= 1;
    }

    var dateJoined = moment(date_joined); // Convert joining date to moment object
    var dateLeft = moment(date_left); // Convert leaving date to moment object

    // Get the year for both joining and leaving
    var yearJoined = dateJoined.year();
    var yearLeft = dateLeft.year();

    // Compare the years
    if (yearJoined === yearLeft) {
      var joinMonth = dateJoined.month() + 1;
      var diff = (currentMonth - joinMonth) / (12 - joinMonth);
      console.log(diff);
      var prorated_leave_award2 = Math.round(2 * (summary[0]?.cf / (12 - joinMonth)) * (12 - currentMonth))/2;
    } else {
      var diff = (currentMonth) / 12;
      console.log(diff); //0.6666
      // var prorated_leave_award2 = diff * summary[0]?.entitled; // 2.5
      var prorated_leave_award2 = Math.round(2 * (summary[0]?.entitled / 12) * (12 - currentMonth))/2; // 2.5
      console.log(prorated_leave_award2)
    }

    // var diff = (currentMonth - 1) / 12;
    // console.log(diff);
    
    let pct_to_be_prorated = 1.0 - diff;
    // let prorated_leave_award2 = diff * entity.leave_awarded_this_year;
    // let new_leave_balance2 = +entity.leave_bf + +prorated_leave_award2 - +entity.leave_taken_this_year;
    // var new_leave_balance2 = +summary[0]?.cf - +prorated_leave_award2;
    var new_leave_balance2 = +entity.current_leave_balance - +prorated_leave_award2;
    let deduction_required2 = 0;

    if (new_leave_balance2 < 0) {
      deduction_required2 = (-1) *  new_leave_balance2;
      new_leave_balance2 = 0;
    }

    entity.new_leave_balance = Math.round(new_leave_balance2 * 2) / 2;
    entity.deduction_required = Math.round(deduction_required2 * 2) / 2;
    // entity.prorated_leave_awarded = Math.round(prorated_leave_award2 * 2) / 2;
    entity.prorated_leave_awarded = ((Math.round(2 * prorated_leave_award2)/2) * -1);
    entity.prorated_pct_final_year = Math.round(pct_to_be_prorated * 1000) / 10;

    console.log(entity);
    dispatch(updateProrated(entity));
  };

  const handleChangeLeaveProrated = (e) => {
    const entity = { ...leaveProrated, [e.target.name]: e.target.value };

    var date = moment(moment(date_left).format());
    var currentMonth = date.month() + 1;
    console.log(currentMonth);

    // Adjust the currentMonth based on the day of the month
    if (date.date() < 28 && date.date() > 12) {
      currentMonth -= 0.5;  // Add 1 month if before the 13th
    }
    else if (date.date() <= 12) {
      currentMonth -= 1;
    }

    var dateJoined = moment(date_joined); // Convert joining date to moment object
    var dateLeft = moment(date_left); // Convert leaving date to moment object

    // Get the year for both joining and leaving
    var yearJoined = dateJoined.year();
    var yearLeft = dateLeft.year();

    // Compare the years
    if (yearJoined === yearLeft) {
      var joinMonth = dateJoined.month() + 1;
      var diff = (currentMonth - joinMonth) / (12 - joinMonth);
    } else {
      var diff = (currentMonth) / 12;
    }

    let pct_to_be_prorated = 1.0 - diff;
    var prorated_leave_award2 = entity.prorated_leave_awarded;
    var new_leave_balance2 = +summary[0]?.cf - +prorated_leave_award2;
    let deduction_required2 = 0;

    if (new_leave_balance2 < 0) {
      deduction_required2 = (-1) *  new_leave_balance2;
      new_leave_balance2 = 0;
    }

    entity.new_leave_balance = Math.round(new_leave_balance2 * 2) / 2;
    entity.deduction_required = Math.round(deduction_required2 * 2) / 2;
    entity.prorated_leave_awarded = ((Math.round(2 * entity.prorated_leave_awarded)/2) * -1);
    entity.prorated_pct_final_year = Math.round(pct_to_be_prorated * 1000) / 10;

    console.log(entity);
    dispatch(updateProrated(entity));
  };

  return (
    <Container>
      {loading && (
          <ThreeDots
          visible={loading}
          height="80"
          width="80"
          color="#4fa94d"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: '9999',
          }}
          />
      )}
      <Card className="CardContent">
        <h1 className="TitleHeader">Employee Resignation</h1>

        <Row className="my-3">
          <Col md={6} sm={6}>
            <Row className="my-3 containerStyle">
              <img src={photo?.photo || defaultPhoto} alt="Employee Photo" className="ovalImageStyle"/><br /><br />
            </Row>
            <Row className="my-3">
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel">Fullname</Form.Label>
              </Col>
              <Col md={8} sm={8}>
                <Form.Control value={fullname} readOnly/>
              </Col>
            </Row>
            <Row className="my-3">
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel">Username</Form.Label>
              </Col>
              <Col md={8} sm={8}>
                <Form.Control value={username} readOnly/>
              </Col>
            </Row>
            <Row className="my-3">
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel">{status.description}</Form.Label>
              </Col>
            </Row>
          </Col>

          <Col md={6} sm={6}>
            <Row className="my-3">
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel">Date Join</Form.Label>
              </Col>
              <Col md={8} sm={8}>
                <Form.Control type="date" value={moment(date_joined).format("YYYY-MM-DD")} readOnly/>
              </Col>
            </Row>
            <Row className="my-3">
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel">Date Leaving</Form.Label>
              </Col>
              <Col md={8} sm={8}>
                <Form.Control type="date" name="date_left" value={moment(date_left).format("YYYY-MM-DD")} onChange={handleDate}/>
              </Col>
            </Row>
            <hr/>
            <Row className="my-3">
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel">Leave Brought Forward</Form.Label>
              </Col>
              <Col md={8} sm={8}>
                <Form.Control name="leave_bf" value={leave_bf} onChange={handleChangeLeave}/>
              </Col>
            </Row>
            <Row className="my-3">
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel">Leave Awarded For This Year</Form.Label>
              </Col>
              <Col md={8} sm={8}>
                <Form.Control name="leave_awarded_this_year" value={summary[0]?.entitled} onChange={handleChangeLeave}/>
              </Col>
            </Row>
            <Row className="my-3">
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel">Leave Taken This Year</Form.Label>
              </Col>
              <Col md={8} sm={8}>
                <Form.Control name="leave_taken_this_year" value={leave_taken_this_year} onChange={handleChangeLeave}/>
              </Col>
            </Row>
            <Row className="my-3">
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel">Current Leave Balance</Form.Label>
              </Col>
              <Col md={8} sm={8}>
                <Form.Control name="current_leave_balance" value={current_leave_balance} onChange={handleChangeLeave}/>
              </Col>
            </Row>
            <hr/>
            <Row className="my-3">
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel">Prorated Leave Award</Form.Label>
              </Col>
              <Col md={8} sm={8}>
                <Form.Control name="prorated_leave_awarded" value={prorated_leave_awarded * -1} onChange={handleChangeLeaveProrated} />
              </Col>
            </Row>
            <Row className="my-3">
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel">Entitled Leave to be deducted (%)</Form.Label>
              </Col>
              <Col md={8} sm={8}>
                <Form.Control name="prorated_pct_final_year" value={prorated_pct_final_year} onChange={handleChangeLeaveProrated} />
              </Col>
            </Row>
            <Row className="my-3">
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel">New Leave Balance</Form.Label>
              </Col>
              <Col md={8} sm={8}>
                <Form.Control name="new_leave_balance" value={new_leave_balance} onChange={handleChangeLeaveProrated} />
              </Col>
            </Row>
            <Row className="my-3">
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel">Deduction Required</Form.Label>
              </Col>
              <Col md={8} sm={8}>
                <Form.Control name="deduction_required" value={deduction_required} onChange={handleChangeLeaveProrated} />
              </Col>
            </Row>
          </Col>
        </Row>

        <div className="d-flex justify-content-between my-2">
          <ButtonGroup>
            <Button className="Button" onClick={handleBtnCancel}>
              Back
            </Button>
          </ButtonGroup>
          <ButtonGroup>
            {/* <Button className="Button" onClick={handleBtnPrint}>
              Print
            </Button>
            &nbsp; &nbsp; */}
            <Button className="Button" onClick={handleBtnSubmit}>
              Submit
            </Button>
          </ButtonGroup>
        </div>
      </Card>
    </Container>
  );
};
export default EmployeeResignationForm;