import { Container, Card, Table, Button, ButtonGroup, Form, Col } from "react-bootstrap";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import moment from 'moment';
import { getHolidayYear } from "../../3.Store/HolidaySlice";
import { ThreeDots } from 'react-loader-spinner';

const HolidaysList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const roles = JSON.parse(localStorage.getItem("roles"));
  const isHR = roles.includes("HR-HOLIDAY");
  
  const getYearFromQuery = () => {
    const params = new URLSearchParams(location.search);
    const year = params.get('year');
    return year ? parseInt(year) : new Date().getFullYear();
  };

  const [selectedYear, setSelectedYear] = useState(getYearFromQuery());
  const holiday = useSelector(state => state.HolidaySlice.holidays);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(getHolidayYear({year: selectedYear}))
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [selectedYear]);

  // Set the date format to '04 January 2020'
  function formatDate(dateString) {
      // Convert dateString to Date object
      return moment(dateString).format('DD MMMM YYYY');
  }

  // Handle Button
  const handleCreate = () => {
    navigate(`/HolidayForm?year=${selectedYear}`);
  };

  const handleUpdate = (id) => {
    navigate(`/HolidayForm/${id}?year=${selectedYear}`);
  };

  // Generate options for year dropdown (e.g., 2020-2024)
  const renderYearOptions = () => {
    const endYear = new Date().getFullYear() + 2;
    const startYear = endYear - 4;
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
      years.push(<option key={year} value={year}>{year}</option>);
    }
    return years;
  };

  const handleBtnBack = () => {
    navigate(-1);
  };

  return (
    <Container>
      {loading && (
        <ThreeDots
          visible={loading}
          height="80"
          width="80"
          color="#4fa94d"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: '9999',
          }}
        />
      )}
      <Card className="CardContent">
        <h1 className="TitleHeader">Holidays</h1>

        <Col md={1} sm={1} style={{ marginLeft: "auto" }}>
          {/* Dropdown menu for selecting the year */}
          <Form.Select 
            value={selectedYear}
            onChange={(e) => setSelectedYear(parseInt(e.target.value))}
          >
            {renderYearOptions()}
          </Form.Select><br/>
        </Col>
        
        <Table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Day</th>
              <th>Holiday</th>
              {isHR && (
                <th>Edit</th>
              )}
            </tr>
          </thead>
          {!loading && (
            <tbody>
              {holiday.map(({ id, date, description }, i) => {
                return (
                  <tr key={i} style={{ cursor: "pointer" }}>
                    <td>{formatDate(date.date)}</td>
                    <td>{moment(date.date).format('dddd')}</td>
                    <td>{description}</td>
                    {isHR && (
                      <td onClick={() => handleUpdate(id)}>
                        <FontAwesomeIcon icon={faPenToSquare} />
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          )}
        </Table>

        <div className="d-flex justify-content-between">
          <ButtonGroup className="my-2">
            <Button className="Button" onClick={handleBtnBack}>
              Back
            </Button>
          </ButtonGroup>

          {isHR && (
            <ButtonGroup className="my-2">
              <Button className="Button" onClick={() => handleCreate()}>
                Add
              </Button>
            </ButtonGroup>
          )}
        </div>

      </Card>
    </Container>
  );
};
export default HolidaysList;