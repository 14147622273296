import React, { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Keycloak from "keycloak-js";

import NavbarMenu from "./2.Commons/NavbarMenu";
import FooterMenu from "./2.Commons/FooterMenu";
import Login from "./1.Components/Login/Login";
import Homepage from "./1.Components/Homepage/Homepage";
import LeaveApplicationList from "./1.Components/LeaveApplication/LeaveApplicationList";
import LeaveApplicationForm from "./1.Components/LeaveApplication/LeaveApplicationForm";
import MedicalClaimList from "./1.Components/MedicalClaim/MedicalClaimList";
import MedicalClaimForm from "./1.Components/MedicalClaim/MedicalClaimForm";
import LeaveApprovalList from "./1.Components/LeaveApproval/LeaveApprovalList";
import LeaveApprovalForm from "./1.Components/LeaveApproval/LeaveApprovalForm";
import HolidaysList from "./1.Components/Holiday/HolidaysList";
import HolidayForm from "./1.Components/Holiday/HolidayForm";
import StaffOnLeaveList from "./1.Components/StaffOnLeave/StaffOnLeaveList";
import StaffInformationForm from "./1.Components/StaffInformation/StaffInformationForm";
import LeaveReportList from "./1.Components/LeaveReport/LeaveReportList";
import LeaveReportForm from "./1.Components/LeaveReport/LeaveReportForm";
import LeaveSummaryList from "./1.Components/LeaveSummary/LeaveSummaryList";
import LeaveAwardList from "./1.Components/LeaveAward/LeaveAwardList";
import LeaveAwardForm from "./1.Components/LeaveAward/LeaveAwardForm";
import LeaveWithdrawalList from "./1.Components/LeaveWithdrawal/LeaveWithdrawalList";
import LeaveWithdrawalForm from "./1.Components/LeaveWithdrawal/LeaveWithdrawalForm";
import ReviewLeaveList from "./1.Components/Review Leave/ReviewLeaveList";
import ReviewLeaveForm from "./1.Components/Review Leave/ReviewLeaveForm";
import HRLeaveReportList from "./1.Components/HRLeaveReport/HRLeaveReportList";
import HRLeaveReportForm from "./1.Components/HRLeaveReport/HRLeaveReportForm";
import HRLeaveReportView from "./1.Components/HRLeaveReport/HRLeaveReportView";
import HRReviewMedicalList from "./1.Components/HRReviewClaim/HRReviewClaimList"
import HRReviewMedicalForm from "./1.Components/HRReviewClaim/HRReviewClaimForm";
import LeaveAccrualList from "./1.Components/LeaveAccrual/LeaveAccrualList";
import LeaveAccrualForm from "./1.Components/LeaveAccrual/LeaveAccrualForm";
import LeaveAccrualYearEndList from "./1.Components/LeaveAccrualYearEnd/LeaveAccrualYearEndList";
import EmployeeList from "./1.Components/Employee/EmployeeList";
import EmployeeForm from "./1.Components/Employee/EmployeeForm";
import EmployeeResignationForm from "./1.Components/Employee/EmployeeResignationForm"
import UserAccessList from "./1.Components/UserAccess/UserAccessList";
import UserAccessForm from "./1.Components/UserAccess/UserAccessForm";
import SUReviewClaimList from "./1.Components/SUReviewClaim/SUReviewClaimList";
import SUReviewClaimForm from "./1.Components/SUReviewClaim/SUReviewClaimForm";

function App() {
  const [authenticated, setAuthenticated] = useState(false);
  const current_user = JSON.parse(localStorage.getItem("current_user"));

  // useEffect(() => {
  //   // Initialize Keycloak
  //   const keycloak = new Keycloak({
  //     realm: 'HSLTower',
  //     url: 'http://192.168.5.199:8080/auth',
  //     clientId: 'jobapp',
  //   });

  //   keycloak
  //     .init({ onLoad: 'login-required' })
  //     .then((authenticated) => {
  //       if (authenticated) {
  //         // User is authenticated. Store the session in localStorage.
  //         localStorage.setItem('keycloakToken', keycloak.token);
  //         localStorage.setItem('keycloakRefreshToken', keycloak.refreshToken);
  //         setAuthenticated(true);
  //       } else {
  //         console.error('User not authenticated');
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Keycloak initialization error', error);
  //     });

  //   // Clean up Keycloak when the component unmounts
  //   return () => {
  //     keycloak.logout();
  //   };
  // }, []);

  return (
    <>
      <Router basename="">
        {current_user && (
          <NavbarMenu />
        )}      
        
        <div className="App">
          <Routes>
            <Route path="/Login" element={<Login />} />
            <Route path="/" element={<Homepage />} />
            
            <Route path="/LeaveApplicationList" element={<LeaveApplicationList />} />
            <Route path="/LeaveApplicationForm" element={<LeaveApplicationForm />} />
            <Route path="/LeaveApplicationForm/:id" element={<LeaveApplicationForm />} />
            
            <Route path="/MedicalClaimList" element={<MedicalClaimList />} />
            <Route path="/MedicalClaimForm" element={<MedicalClaimForm />} />
            <Route path="/MedicalClaimForm/:id" element={<MedicalClaimForm />} />
            <Route path="/MedicalClaimForm/leave/:leaveApplicationId" element={<MedicalClaimForm />} />

            <Route path="/LeaveApprovalList" element={<LeaveApprovalList />} />
            <Route path="/LeaveApprovalForm" element={<LeaveApprovalForm />} />
            <Route path="/LeaveApprovalForm/:id" element={<LeaveApprovalForm />} />

            <Route path="/SUReviewClaimList" element={<SUReviewClaimList />} />
            <Route path="/SUReviewClaimForm" element={<SUReviewClaimForm />} />
            <Route path="/SUReviewClaimForm/:id" element={<SUReviewClaimForm />} />

            <Route path="/Holidays" element={<HolidaysList />} />
            <Route path="/HolidayForm" element={<HolidayForm />} />
            <Route path="/HolidayForm/:id" element={<HolidayForm />} />

            <Route path="/StaffInformations" element={<StaffInformationForm />} />

            <Route path="/StaffOnLeave" element={<StaffOnLeaveList />} />

            <Route path="/LeaveReportList" element={<LeaveReportList />} />
            <Route path="/LeaveReportForm/:id" element={<LeaveReportForm />} />
            
            <Route path="/LeaveSummaryList" element={<LeaveSummaryList />} />

            <Route path="/LeaveAwardList" element={<LeaveAwardList />} />
            <Route path="/LeaveAwardForm" element={<LeaveAwardForm />} />
            <Route path="/LeaveAwardForm/:id" element={<LeaveAwardForm />} />

            <Route path="/LeaveWithdrawalList" element={<LeaveWithdrawalList />} />
            <Route path="/LeaveWithdrawalForm" element={<LeaveWithdrawalForm />} />
            <Route path="/LeaveWithdrawalForm/:id" element={<LeaveWithdrawalForm />} />

            <Route path="/ReviewLeaveList" element={<ReviewLeaveList />} />
            <Route path="/ReviewLeaveForm" element={<ReviewLeaveForm />} />
            <Route path="/ReviewLeaveForm/:id" element={<ReviewLeaveForm />} />

            <Route path="/HRLeaveReportList" element={<HRLeaveReportList />} />
            <Route path="/HRLeaveReportForm" element={<HRLeaveReportForm />} />
            <Route path="/HRLeaveReportForm/:id" element={<HRLeaveReportForm />} />
            <Route path="/HRLeaveReportView/:id" element={<HRLeaveReportView />} />

            <Route path="/HRReviewMedicalList" element={<HRReviewMedicalList />} />
            <Route path="/HRReviewMedicalForm" element={<HRReviewMedicalForm />} />
            <Route path="/HRReviewMedicalForm/:id" element={<HRReviewMedicalForm />} />

            <Route path="/LeaveAccrualList" element={<LeaveAccrualList />} />
            <Route path="/LeaveAccrualForm" element={<LeaveAccrualForm />} />
            <Route path="/LeaveAccrualForm/:id" element={<LeaveAccrualForm />} />

            <Route path="/EmployeeList" element={<EmployeeList />} />
            <Route path="/EmployeeForm" element={<EmployeeForm />} />
            <Route path="/EmployeeForm/:id" element={<EmployeeForm />} />
            <Route path="/EmployeeResignationForm/:id" element={<EmployeeResignationForm />} />

            <Route path="/UserAccessList" element={<UserAccessList />} />
            <Route path="/UserAccessForm/:id" element={<UserAccessForm />} />

            <Route path="/LeaveAccrualYearEndList" element={<LeaveAccrualYearEndList />} />
          </Routes>
        </div>
        {current_user && (
          <FooterMenu />
        )}           
      </Router>
    </>
  );
}

export default App;