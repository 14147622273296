import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Container, Card, Table, ButtonGroup, Button, Col, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoltLightning, faPenToSquare, faCheck, faHourglass, faReply, faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { getListsOfLeaveWithdrawal } from '../../3.Store/LeaveWithdrawalSlice';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import { ThreeDots } from 'react-loader-spinner';
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const LeaveWithdrawalList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const withdrawalList = useSelector(state => state.LeaveWithdrawalSlice.lists);
  const [loading, setLoading] = useState(false);
  const pageSize = 20; // Number of items per page
  const totalPages = Math.ceil(withdrawalList.length / pageSize);
  const { page } = useParams(); // Retrieve page parameter

  const getPageFromQuery = () => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    return page ? parseInt(page) : 1;
  };

  const [currentPage, setCurrentPage] = useState(getPageFromQuery());

  useEffect(() => {
    setLoading(true);
    dispatch(getListsOfLeaveWithdrawal())
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    // Set current page based on URL parameter
    if (page) {
      setCurrentPage(Number(page));
    }
  }, [page]);

  const handleBtnBack = () => {
    navigate(-1);
  };

  const handleClickView = id => {
    // navigate(`/LeaveWithdrawalForm/${id}`);
    navigate(`/LeaveWithdrawalForm/${id}?page=${currentPage}`);
  };

  const handlePreviousPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };

  const renderStaffList = () => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize, withdrawalList.length);

    return withdrawalList
      .slice()
      .sort((a, b) => new Date(b.created) - new Date(a.created))
      .slice(startIndex, endIndex)
      .map(list => (
        <tr key={list.id} style={{ cursor: "pointer" }}>
          <td>{moment(list.created).format('YYYY-MM-DD')}</td>
          <td>{list.leave_application.employee.fullname}</td>
          <td>{list.leave_application.leave_type.description}</td>
          <td>{list.leave_application.description}</td>

          {list.status.short_description === 'APPROVED' && (
            <td>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>{list.status.short_description}</Tooltip>}
              >
                <span>
                  <FontAwesomeIcon icon={faCheck} style={{ color: '#3fa64b' }} />
                </span>
              </OverlayTrigger>
            </td>
            
          )}

          {list.status.short_description === 'PENDING' && (
            <td>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>{list.status.short_description}</Tooltip>}
              >
                <span>
                  <FontAwesomeIcon icon={faHourglass} style={{ color: '#FFD43B' }} />
                </span>
              </OverlayTrigger>
            </td>
          )}

          {list.status.short_description === 'WITHDRAWING' && (
            <td>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>{list.status.short_description}</Tooltip>}
              >
                <span>
                  <FontAwesomeIcon icon={faReply} style={{ color: '#f22c2c' }} />
                </span>
              </OverlayTrigger>
            </td>
          )}

          <td onClick={() => handleClickView(list.id)}>
            <FontAwesomeIcon icon={faPenToSquare} />
          </td>
        </tr>
      ));
  };

  return (
    <Container>
      {loading && (
        <ThreeDots
          visible={loading}
          height="80"
          width="80"
          color="#4fa94d"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: '9999',
          }}
        />
      )}
      <Card className="CardContent">
        <h1 className="TitleHeader">HR Leave Withdrawals</h1>
        <h1 className="MiniTitleHeader">List</h1>

        <Table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Employee</th>
              <th>Type</th>
              <th>Description</th>
              <th>Status</th>
              <th>
                <FontAwesomeIcon icon={faBoltLightning} />
              </th>
            </tr>
          </thead>
          {!loading && (
            <tbody>
              {withdrawalList.length > 0 ? (
                renderStaffList()
              ) : (
                <tr>
                  <td colSpan="6">No results found</td>
                </tr>
              )}
            </tbody>
          )}
        </Table>

        <table>
          <tbody>
            <tr>
              <td className="FormLabel">
                <FontAwesomeIcon icon={faHourglass} color="#FFC107" />
                &nbsp;Pending
                &ensp;&ensp;
                <FontAwesomeIcon icon={faCheck} color="#28A744" />
                &nbsp;Approved
                &ensp;&ensp;
                <FontAwesomeIcon icon={faReply} color="#DC3545" />
                &nbsp;Rejected
              </td>
            </tr>
          </tbody>
        </table>

        <Col style={{ marginLeft: 'auto' }}>
          <Form.Label className="FormLabel">{`${currentPage} of ${totalPages}`}</Form.Label> &ensp;
          <Form.Label className="FormLabel" onClick={handlePreviousPage} disabled={currentPage === 1}>
            <FontAwesomeIcon icon={faChevronLeft} style={{ color: '#ffffff' }} />
          </Form.Label>{' '}
          &ensp;
          <Form.Label className="FormLabel" onClick={handleNextPage} disabled={currentPage === totalPages}>
            <FontAwesomeIcon icon={faChevronRight} style={{ color: '#ffffff' }} />
          </Form.Label>
        </Col>

        <div className="d-flex justify-content-between">
          <ButtonGroup className="my-2">
            <Button className="Button" onClick={handleBtnBack}>
              Back
            </Button>
          </ButtonGroup>
        </div>
      </Card>
    </Container>
  );
};

export default LeaveWithdrawalList;
